import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UniversalSearch from "../../components/shared/UniversalSearch";
import SuspectsTable from "../../components/Suspects/SuspectsTable";
import { SuspectsListContainer } from "./SuspectsList.styled";
import blur from "../../../src/images/blur_suspect.png";
import blur2 from "../../../src/images/suspectacessdenied.png";

import useFetchSuspects from "../../components/Suspects/hooks/useFetchSuspects.jsx";

import {
  setSuspectData,
  setFormattedSuspectData,
} from "../../redux/slices/suspect.js";

export default function SuspectsList({ instance }) {
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const [parsedSuspects, setSuspects] = useState([]);
  const [filteredSuspects, setFilteredSuspects] = useState(parsedSuspects);
  const { suspects, fetchSuspects, hasMoreSuspects, error } =
    useFetchSuspects(instance);

  const getSuspects = () => {
    const parsedData = suspects.map((item, index) => {
      // Parse the last updated date/time into a more readable format based on locale
      const lastUpdatedDateTime = new Date(item.lastUpdatedDate);
      const lastUpdatedDate = lastUpdatedDateTime.toLocaleDateString();
      const lastUpdatedTime = lastUpdatedDateTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      return {
        name: item.name,
        operationId: item.operations[0].id,
        operation: item.operations[0].name,
        suspectRiskLevel: item.suspectRiskLevel,
        formattedPhoneNumber: item.formattedPhoneNumber,
        carrier: item.properties.Carrier,
        ageRange: item.properties.AgeRange,
        totalMessages: "TBD",
        id: item.id, // TODO: Use suspectId from fetched data. However, it is currently all 0s, which needs to be fixed
        totalBotsContacted: item.botIds.length,
        lastContactedDate: lastUpdatedDate,
        lastContactedTime: lastUpdatedTime,
        notes: item.notes,
        totalOperationCount: item.operationIds.length,
        totalOperations: item.operations,
        orgId: item.organizationId,
        lastReportedCallerIdInfo: item.whitePages,
        lastUpdatedCallerId: item.whitePages,
        lastUpdatedCriminalSearch: item.whitePages,
        therapeuticMessageSent: item.whitePages,
      };
    });

    setSuspects(parsedData);
    dispatch(setFormattedSuspectData(parsedData));
    dispatch(setSuspectData(suspects));
  };

  const filterSuspects = (query) => {
    if (!query) {
      setFilteredSuspects(parsedSuspects);
    } else {
      const filtered = parsedSuspects.filter((item) =>
        // Check if any field in the suspect object contains the query
        Object.values(item).some((field) =>
          field?.toString()?.toLowerCase()?.includes(query.toLowerCase())
        )
      );
      setFilteredSuspects(filtered);
    }
  };

  useEffect(() => {
    getSuspects();
  }, [selectedOrg, suspects]);

  useEffect(() => {
    setFilteredSuspects(parsedSuspects);
  }, [parsedSuspects]);

  return (
    <SuspectsListContainer>
      <div className="flex-column flex-sm-row mb-4 mb-sm-0" style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>All Suspects</h1>
        <UniversalSearch
          placeholder={"Name, Neighborhood, ...etc"}
          filterFunction={filterSuspects}
        />
      </div>

      {parsedSuspects.length > 0 ? (
        <SuspectsTable
          suspects={filteredSuspects}
          fetchSuspects={fetchSuspects}
          hasMoreSuspects={hasMoreSuspects}
        />
      ) : error ? (
        <img src={blur2} width={"100%"} />
      ) : (
        <img style={{ width: "100%" }} src={blur} />
      )}
    </SuspectsListContainer>
  );
}
