import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpData } from "../redux/slices/operation";
import { fetchWithAuth } from "../utils/authPopup";

const useGetOperations = (instance) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const [loading, setLoading] = useState(false);
  const [blurState, setBlurState] = useState();
  const [error, setError] = useState(false);

  const getOperations = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const opsList = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/operations`
      );

      dispatch(setOpData(opsList));
      setBlurState(opsList);
    } catch (error) {
      console.error(error);
      if (error === "Forbidden") {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, instance, selectedOrg.id]);

  useEffect(() => {
    getOperations();
  }, [getOperations, instance, selectedOrg]);

  return { getOperations, loading, error, blurState };
};

export default useGetOperations;
