import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        font: { size: 16 },
        color: "white",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 7,
        font: { size: 16 },
        color: "white",
      },
      grid: {
        color: "rgba(255, 255, 255, 0.2)",
      },
    },
    y: {
      ticks: {
        font: { size: 16 },
        color: "white",
      },
      grid: {
        color: "rgba(255, 255, 255, 0.2)",
      },
    },
  },
  layout: { padding: 10 },
  elements: {
    point: {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
  },
  responsiveAnimationDuration: 0,
};

/**
 * Component for displaying a line chart of suspect and messages over time.
 * @param {Object[]} data - Array of objects with date, numMessages, and numSuspects properties.
 * @param {Date} startDate - Start date of the date range to filter the data.
 * @param {Date} endDate - End date of the date range to filter the data.
 * @returns {JSX.Element} - Rendered component.
 */
export default function SuspectMessages(props) {
  let filteredData = props.data;

  // If startDate and endDate are defined, filter the data.
  if (props.startDate && props.endDate) {
    filteredData = props.data.filter((item) => {
      const date = new Date(item.date);
      return date >= props.startDate && date <= props.endDate;
    });
  }

  // Define the labels and data for the chart.
  const labels = filteredData.map((item) => item.date);
  const numMessages = filteredData.map((item) => item.numMessages);
  const numSuspects = filteredData.map((item) => item.numSuspects);

  const data = {
    labels,
    datasets: [
      {
        label: "Suspects",
        data: numSuspects,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Messages",
        data: numMessages,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const [noData] = useState({
    labels: ["Coming Soon"],
    datasets: [
      {
        label: "Coming Soon",
        backgroundColor: ["clear"],
        borderColor: "clear",
        data: [],
      },
    ],
  });

  function displayData(data) {
    if (data.datasets[0].data.length === 0) {
      return noData;
    } else {
      return data;
    }
  }

  return (
    <Line
      className="p-4 m-2"
      style={{ background: "black" }}
      options={options}
      data={displayData(data)}
    />
  );
}
