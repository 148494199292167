import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    activeAccount: {},
    isLoggedIn: false,
  },
  reducers: {
    setActiveAccount: (state, action) => {
      state.activeAccount = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveAccount, setIsLoggedIn } = authSlice.actions;

export default authSlice.reducer;
