import React from "react";
import { CardTitle } from "reactstrap";
import { useSelector } from "react-redux";
import { MiniCardContainer, MiniTable } from "./MiniCard.styled";
import AddOperation from "../../components/AddOperation/AddOperation";

export default function OperationsMiniCard({ title, content }) {
  const theme = useSelector((state) => state.theme);

  return (
    <MiniCardContainer className="h-100  p-3" body theme={theme}>
      <div className="d-flex justify-content-between">
        <CardTitle tag="h5" className="mb-3">
          <b>{title}</b>
        </CardTitle>
        <AddOperation />
      </div>
      <MiniTable className="mt-3">
        <tbody style={{ fontSize: "18px" }}>{content}</tbody>
      </MiniTable>
    </MiniCardContainer>
  );
}
