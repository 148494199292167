import React, { useEffect } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ConversationType } from "./constants";

const ChatFilterSelectors = ({
  selectedOperation,
  setSelectedOperation,
  selectedBot,
  setSelectedBot,
  onFilterChange,
  onlyActiveOperations,
  setOnlyActiveOperations,
  selectedType,
  onSwitchType,
}) => {
  const operations = useSelector((state) => state.operation.data);

  useEffect(() => {
    onFilterChange();
  }, [selectedOperation, selectedBot]);

  const handleOperationSelect = (operation) => {
    setSelectedOperation(operation);
    setSelectedBot(null);
  };

  const handleClearOperation = () => {
    setSelectedOperation(null);
    setSelectedBot(null);
  };

  const handleClearBot = () => {
    setSelectedBot(null);
  };

  return (
    <div style={{ maxWidth: "290px" }}>
      <Form.Check
        type="switch"
        label="Only Active Operations"
        style={{ marginLeft: "12px" }}
        checked={onlyActiveOperations}
        onChange={() => setOnlyActiveOperations((prev) => !prev)}
      />
      <DropdownButton title={selectedType}>
        {Object.values(ConversationType).map((type) => (
          <Dropdown.Item
            key={type}
            onClick={() => onSwitchType(type)}
          >
            {type}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <DropdownButton
        title={selectedOperation ? selectedOperation.name : "Select Operation"}
      >
        {selectedOperation ? (
          <Dropdown.Item onClick={handleClearOperation}>Clear</Dropdown.Item>
        ) : null}
        {operations.map((operation) => (
          <Dropdown.Item
            key={operation.id}
            onClick={() => handleOperationSelect(operation)}
            style={{
              maxWidth: "100%",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {operation.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {selectedOperation && (
        <DropdownButton
          title={selectedBot ? selectedBot.phoneNumber : "Select Bot Number"}
        >
          {selectedBot ? (
            <Dropdown.Item onClick={handleClearBot}>Clear</Dropdown.Item>
          ) : null}
          {selectedOperation.bots?.map((bot) => (
            <Dropdown.Item key={bot.id} onClick={() => setSelectedBot(bot)}>
              {bot.phoneNumber}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
    </div>
  );
};

export default ChatFilterSelectors;
