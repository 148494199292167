import React, { useState, useEffect } from "react";
import "./Help.css";

import { PrimaryButton } from "../../shared/styles/Buttons.styled";
import BotFormDropdown from "../../components/AddBot/BotFormDropdown";
import { Dropdown } from "react-bootstrap";
import { fetchWithAuth } from "../../utils/authPopup";
const faqData = {
  Tutorials: [
    {
      type: "video",
      question: "Botpage Tutorial 1",
      answer: "botpage.mp4",
    },
    {
      type: "video",

      question: "Botpage Tutorial 2",
      answer: "botpagetwo.mp4",
    },
    {
      type: "video",

      question: "Onboarding Tutorial",
      answer: "onboarding.mp4",
    },
    {
      type: "video",

      question: "Operationpage Tutorial",
      answer: "operationpage.mp4",
    },
    {
      type: "video",

      question: "App Tour Tutorial",
      answer: "tour.mp4",
    },
  ],
  "Bot Management": [
    {
      question:
        "Can I edit a bot once I’ve created them and change information?",
      answer: "No. The bot properties are read-only after creating the bot.",
    },
    {
      question: "Can I reuse a bot for another operation that I create?",
      answer:
        "No – the phone numbers and bots are tied to a specific operation and cannot be reused for other operations. You can create a new bot (same area code, but different number) and use the same properties.",
    },
    {
      question: "Once an operation is in progress, can I change the end date?",
      answer:
        "Yes, you can update the end date, as long it’s before the end date has passed. At the end date, we close out the bot, free up the phone number, and send deterrence messages for existing conversations. Once this has happened, the operation has been closed out, and updating the end date would have no effect.",
    },
    {
      question: "When does the bot switch to another language?",
      answer:
        "The bot detects the languages on new incoming messages from suspects. We assume English but will try to detect on messages longer than 15 characters. If we do find a language besides English, the bot will switch to that language. Once the bot has detected a 2nd language, we will only switch back to English or that language for the rest of the conversation and will switch based on the suspect messages (respond in their message language, either English or a 2nd language).",
    },
    {
      question:
        "Why does the bot stop responding in a conversation (even when there are more incoming suspect messages)?",
      answer:
        "The bot personas have a set number of responses before they will stop responding to suspect messages. This is the design of the bots. In the future, we may consider a more meandering large language model bot, but for now, the bots have approx. 5-30 responses (varies by the bot persona).",
    },
  ],
  "User Management": [
    {
      question: "Can I change a password for a member of my team?",
      answer:
        "No, not yet – but this feature will be coming soon. The way it will work is that the password can be reset, and you’ll get a temporary password – the user would need to choose a new password on the next login.",
    },
    {
      question: "How do I delete a user?",
      answer:
        "Users can be deleted by clicking the ‘x’ on the Users table. NOTE: only administrators can add or remove users.",
    },
  ],
  "Platform and Support": [
    {
      question: "How will I be notified about new updates/changes to Gracie?",
      answer: "By Bird",
    },
    {
      question: "What if I’m having technical issues with the platform?",
      answer:
        "Please click 'Help' in the upper right of the platform to get questions/answers. If you don’t find your answer, please email help@streetgrace.org for additional help.",
    },
  ],
};

const Help = ({ instance }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [category, setCategory] = useState("Show All");
  const [videoUrls, setVideoUrls] = useState({});

  const toggleAnswer = async (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
      const item = faqItems[index];
      if (item.type === "video" && !videoUrls[index]) {
        try {
          const response = await fetchWithAuth(
            instance,
            "GET",
            `api/faq/GetMediaUrl/${item.answer}`
          );
          setVideoUrls((prevVideoUrls) => ({
            ...prevVideoUrls,
            [index]: response.url,
          }));
        } catch (error) {
          console.error("Error fetching video URL:", error);
        }
      }
    }
  };

  const categoryData = [
    { name: "Show All" },
    { name: "Tutorials" },
    { name: "User Management" },
    { name: "Platform and Support" },
    { name: "Bot Management" },
  ];

  const getFaqItems = () => {
    if (category === "Show All") {
      return Object.values(faqData).flat();
    }
    return faqData[category] || [];
  };

  const faqItems = getFaqItems();

  return (
    <div className="faq-container">
      <div className="center">
        <h1 className="faq-header">FAQ</h1>
        <Dropdown>
          <Dropdown.Toggle>{category}</Dropdown.Toggle>
          <Dropdown.Menu>
            {categoryData.map((element, index) => (
              <Dropdown.Item
                key={index}
                eventKey={element.name}
                active={category === element.name}
                onClick={() => setCategory(element.name)}
              >
                {element.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {faqItems.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAnswer(index)}>
            <span>{item.question}</span>
            <button className="faq-toggle-button">
              {openIndex === index ? "-" : "+"}
            </button>
          </div>
          {openIndex === index && (
            <div className="faq-answer">
              {item.type === "video" ? (
                videoUrls[index] ? (
                  <video
                    src={`api/${videoUrls[index]}`}
                    type="video/mp4"
                    controls
                    style={{ width: "50%" }}
                  />
                ) : (
                  <p>Loading video...</p>
                )
              ) : (
                <p>{item.answer}</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Help;
