import React, { useState, useEffect } from "react";
import "./PlatformData.css";
import { Doughnut } from "react-chartjs-2";
import mapSvg from "../../images/mapsvg.svg";

const Platformdata = ({ instance, id }) => {
  const [platformData, setPlatformData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch("api/platformdata", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setPlatformData(data);
      } catch (err) {
        setError(`Failed to fetch platform data: ${err.message}`);
      }
    };
    fetchOrgData();
  }, [instance]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!platformData) {
    return <div>Loading...</div>;
  }

  const launchDate = new Date("March 29, 2024");
  const currentDate = new Date();
  const timeDifference = currentDate - launchDate;
  const daysOfActivity = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  const nonRepeatSuspects =
    platformData.totalSuspectsFound - platformData.totalRepeatSuspectsFound;

  const messagesDoughnutData = {
    labels: [
      `Messages Incoming to Bots (${platformData.totalMessagesIncomingToBots.toLocaleString()})`,
      `Messages Outgoing to Suspects (${platformData.totalMessagesOutgoingToSuspects.toLocaleString()})`,
    ],
    datasets: [
      {
        data: [
          platformData.totalMessagesIncomingToBots,
          platformData.totalMessagesOutgoingToSuspects,
        ],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  const messagesChartOptions = {
    plugins: {
      legend: {
        labels: {
          color: "#FFFFFF",
        },
      },
    },
  };
  const suspectChartOptions = {
    plugins: {
      legend: {
        labels: {
          color: "#FFFFFF",
        },
      },
    },
  };

  // Doughnut chart data for Suspects
  const suspectsDoughnutData = {
    labels: [
      `Repeat Suspects Found (${platformData.totalRepeatSuspectsFound.toLocaleString()})`,
      `Non-Repeat Suspects Found (${nonRepeatSuspects.toLocaleString()})`,
    ],
    datasets: [
      {
        data: [platformData.totalRepeatSuspectsFound, nonRepeatSuspects],
        backgroundColor: ["#FFCE56", "#FF6384"],
        hoverBackgroundColor: ["#FFCE56", "#FF6384"],
      },
    ],
  };

  return (
    <div className="platform-data">
      <div className="title">
        <h2 style={{ marginRight: "20px" }}> Platform Data</h2>
        <div className="innertitle">
          <span style={{ display: "flex" }}>
            <h5 style={{ margin: 0, marginRight: "5px" }}>Launch Date</h5> -
            <p style={{ margin: 0, paddingLeft: "5px" }}>
              {launchDate.toDateString()}
            </p>
          </span>
          <p style={{ margin: 0 }}>- {daysOfActivity} days of activity</p>
        </div>
      </div>
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Total Bots</h3>
          <p>{platformData?.totalBots.toLocaleString()}</p>
        </div>

        <div className="stat-card">
          <h3>Total Operations</h3>
          <p>{platformData?.totalOperations.toLocaleString()}</p>
        </div>

        <div className="stat-card">
          <h3>Total Healing Resource Links Sent</h3>
          <p>{platformData?.totalTherapeuticMessagesSent.toLocaleString()}</p>
        </div>
        <div className="stat-card">
          <h3>Total Transactions Intercepted</h3>
          <p>{platformData?.totalTransactionsIntercepted.toLocaleString()}</p>
        </div>
      </div>
      <div className="cahrtFlex">
        <div className="chart-container">
          <h3>Message Statistics</h3>
          <Doughnut
            data={messagesDoughnutData}
            options={messagesChartOptions}
          />
        </div>
        <div className="chart-container-map">
          <img
            src={mapSvg}
            alt="Map"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "20px",
              marginTop: "20px",
            }}
          />
        </div>
        <div className="chart-container">
          <h3>Suspect Statistics</h3>
          <Doughnut data={suspectsDoughnutData} options={suspectChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default Platformdata;
