import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  OpsTable,
  OpsTableContainer,
  OpsTbody,
  OpsTd,
  OpsTh,
  OpsThead,
  OpsTr,
} from "./NewOpTable.styled";
import { OpsName, TableFilter } from "./OperationsTable.styled";
import { InfoIcon } from "../../shared/styles/InfoIcon.styled";
import SortIcon from "../../shared/SortIcon/SortIcon";
import { setSelectedOp } from "../../redux/slices/operation";
import { useNavigate } from "react-router-dom";

/**
 * Component that renders the table in the Operations page.
 * @param {Object[]} [operations] - The array of operations to display in the table
 * @param {number} [itemsPerPage] - The number of items to display per page
 * @returns {JSX.Element} - The OperationsTable component
 */

export const OP = ({ children, ...rest }) => {
  return <OpsTableContainer {...rest}>{children}</OpsTableContainer>;
};

OP.Table = ({ children, ...rest }) => {
  return <OpsTable {...rest}>{children}</OpsTable>;
};

OP.Head = ({ children, ...rest }) => {
  return <OpsThead {...rest}>{children}</OpsThead>;
};

OP.Body = ({ children, ...rest }) => {
  return <OpsTbody {...rest}>{children}</OpsTbody>;
};

OP.Tr = ({ children, ...rest }) => {
  return <OpsTr {...rest}>{children}</OpsTr>;
};

OP.Th = ({ children, ...rest }) => {
  return <OpsTh {...rest}>{children}</OpsTh>;
};

OP.Td = ({ children, ...rest }) => {
  return <OpsTd {...rest}>{children}</OpsTd>;
};

export default function OperationsTable() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  const operations = useSelector((state) => state.operation.data);
  const [sortField, setSortField] = useState("endDate");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filter, setFilter] = useState("");
  const [items, setItems] = useState(operations);

  const sortedItems = items.slice().sort((a, b) => {
    const order = sortOrder === "asc" ? 1 : -1;
    return order * (a[sortField] > b[sortField] ? 1 : -1);
  });

  const currentItems = sortedItems;

  // Set the sort order based on the field that was chosen
  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Filter the table based on the input
  const handleFilter = (event) => {
    setFilter(event.target.value);
    if (event.target.value === "") {
      setItems(operations);
    } else {
      setItems(
        operations.filter((operation) =>
          operation.name
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
      );
    }
  };

  const handleOperationEndsSoon = (endDate) => {
    const timeDiff = new Date(endDate).getTime() - new Date().getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysLeft <= 0) {
      return null;
    } else if (daysLeft === 1) {
      return (
        <span>
          <Icon icon="openmoji:warning" /> Ends in 1 day
        </span>
      );
    } else if (daysLeft <= 3) {
      return (
        <span>
          <Icon icon="openmoji:warning" /> Ends in {daysLeft} days
        </span>
      );
    }
  };
  const navigate = useNavigate();

  return (
    <OP theme={theme}>
      <OP.Table className="ext-center p-3 round-table">
        <OP.Head>
          <OP.Tr>
            <OP.Th style={{ cursor: "auto" }}>
              <div className="d-flex align-items-center">
                <span className="ms-2">Name</span>&nbsp;
                <Icon
                  className="ms-2"
                  icon="material-symbols:search"
                  color="#aaa"
                />
                &nbsp;
                <TableFilter
                  className="ops-table-filter"
                  type="text"
                  placeholder="Filter by name"
                  value={filter}
                  onChange={handleFilter}
                ></TableFilter>
              </div>
            </OP.Th>
            <OP.Th onClick={() => handleSort("status")}>
              Status
              <SortIcon
                sortField={"status"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </OP.Th>

            <OP.Th onClick={() => handleSort("startDate")}>
              Start time
              <SortIcon
                sortField={"startDate"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </OP.Th>
            <OP.Th onClick={() => handleSort("endDate")}>
              End time
              <SortIcon
                sortField={"endDate"}
                currField={sortField}
                sortOrder={sortOrder}
              />
            </OP.Th>
          </OP.Tr>
        </OP.Head>
        {operations.length !== 0 ? (
          <OP.Body>
            {currentItems.map((operation, index) => (
              <OP.Tr key={index}>
                <OP.Td style={{ width: "25%" }}>
                  <OpsName>
                    <button
                      style={{
                        color: theme === "light" ? "#000" : "#fff",
                        fontSize: "20px",
                        backgroundColor: "transparent",
                        border: "none",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        dispatch(setSelectedOp(operation));
                        navigate(`/operations/operation/${operation.id}`);
                      }}
                    >
                      {operation.name}
                    </button>
                  </OpsName>
                </OP.Td>
                <OP.Td>
                  {!operation.isActive ? (
                    <>
                      <InfoIcon
                        icon="fluent-mdl2:completed-solid"
                        color="#7ef182"
                      />
                      <span>Completed</span>
                    </>
                  ) : (
                    <>
                      <InfoIcon icon="carbon:in-progress" color="#aaa" />
                      <span>In Progress</span>
                      <br></br>
                      {handleOperationEndsSoon(operation.endDate)}
                    </>
                  )}
                </OP.Td>

                <OP.Td>
                  <br />
                  {new Date(operation.startDate).toLocaleDateString(undefined, {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                  <br />
                  <p style={{ color: "#AAAAAA", fontSize: "16px" }}>
                    {new Date(operation.startDate).toLocaleTimeString(
                      undefined,
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp; Local Time
                  </p>
                </OP.Td>
                <OP.Td>
                  <br />
                  {new Date(operation.endDate).toLocaleDateString(undefined, {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                  <br />
                  <p style={{ color: "#AAAAAA", fontSize: "16px" }}>
                    {new Date(operation.endDate).toLocaleTimeString(undefined, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                    &nbsp;&nbsp;&nbsp;&nbsp; Local Time
                  </p>
                </OP.Td>

                <OP.Td></OP.Td>
              </OP.Tr>
            ))}
          </OP.Body>
        ) : (
          <OP.Body></OP.Body>
        )}
      </OP.Table>
    </OP>
  );
}
