import styled from "styled-components";
import { Card, Input } from "reactstrap";

export const OpsContainer = styled.div`
  margin: 0em 3em 2em 3em;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
`;

export const EditOpCard = styled(Card)`
  background-color: #262944;
  border-radius: 10px;
  padding: 1em;
  margin: 1em 0em 2em 0em;

  p {
    margin: 5px 8px 12px 0;
  }
`;

export const EditBotTable = styled.table`
  width: 100%;
  table-layout: fixed;

  thead {
    font-size: 20px;
  }

  th,
  td {
    padding: 0.5em;
    border-bottom: 1px solid #f8f8f84d;
  }
`;

export const StyledFormCheck = styled.div`
  .form-check-input {
    background-color: transparent;
  }
  .form-check-input:checked {
    border-color: #19dccd;
  }
  .form-check-input:checked[type="radio"] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%2319DCCD%27/%3e%3c/svg%3e");
  }
`;
export const EditOperatorInput = styled.input`
  background-color: #0a0e37;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  margin: 10px;
  width: 100%;
`;
export const EditTimeInput = styled.input`
  background-color: #0a0e37;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  margin: 10px;
  width: 100%;
`;
export const StyledDayInput = styled.input`
  background-color: #1c1f3c;
  color: #aaaaaa;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  margin: 10px;
  width: 35%;
`;

export const MessageInput = styled.textarea`
  background-color: #1c1f3c;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 10px;

  &:focus &:active {
    background-color: #1c1f3c;
    color: #fff;
  }
`;

export const SelectInput = styled(Input)`
  background-color: #1c1f3c;
  border-color: #1c1f3c;
  color: #19dccd;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
`;

export const StyledCard = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  frex-direction: column;
  gap: 10px;
  align-items: center;
`;
