import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { SearchBarContainer, SearchBar } from "./UniversalSearch.styled";

export default function UniversalSearch({ placeholder, filterFunction }) {
  const theme = useSelector((state) => state.theme);
  const [placeholderText, setPlaceholderText] = useState(placeholder);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterFunction(query);
  };

  return (
    <SearchBarContainer>
      <Icon className="ms-2" icon="material-symbols:search" color="#aaa" />
      &nbsp;
      <SearchBar
        type={"text"}
        placeholder={placeholderText}
        value={searchQuery}
        onChange={handleSearch}
        theme={theme}
      />
    </SearchBarContainer>
  );
}
