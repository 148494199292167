import React from "react";
import { CardTitle } from "reactstrap";
import { useSelector } from "react-redux";
import { PrimaryButton } from "../styles/Buttons.styled";
import { MiniCardContainer, MiniTable } from "./MiniCard.styled";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

export default function BotsMiniCard({ title, content }) {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);

  function handleNavigate(e) {
    if (title === "Active Bots") {
      navigate("/bots/create-bot");
    }
  }
  return (
    <MiniCardContainer className="h-100 p-3" body theme={theme}>
      <div className="d-flex justify-content-between">
        <CardTitle tag="h5" className="mb-3">
          <b>{title}</b>
        </CardTitle>
        <PrimaryButton
          onClick={handleNavigate}
          className="px-3 py-2 font-size-sm"
        >
          <Icon icon="ic:baseline-plus" color="white" /> Add new
        </PrimaryButton>
      </div>
      <MiniTable className="mt-3">
        <tbody style={{ fontSize: "18px" }}>{content}</tbody>
      </MiniTable>
    </MiniCardContainer>
  );
}
