import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 3em;
  color: #FFF;
`;

const Message = styled.p`
  font-size: 1.5em;
  color: #FFF;
`;

const NotFound = () => {
  return (
    <Wrapper>
      <div>
        <Heading>We are sorry, the page you requested cannot be found.</Heading>
        <Message>The URL may be mispelled or the page you're looking for is no longer available.</Message>
      </div>
    </Wrapper>
  );
};

export default NotFound;
