import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        msaldata: {},
    },
    reducers: {
        saveConfiguration: (state, config) => {
            state.msaldata = config;
        },
    },
});

export const { saveConfiguration } = settingsSlice.actions;

export default settingsSlice.reducer;
