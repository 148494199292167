import { createSlice } from "@reduxjs/toolkit";

export const operation = createSlice({
  name: "organization",
  initialState: {
    data: [],
    selectedOp: false,
  },
  reducers: {
    setOpData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedOp: (state, action) => {
      state.selectedOp = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpData, setSelectedOp } = operation.actions;

export default operation.reducer;
