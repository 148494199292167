import React, { useEffect, useState } from "react";
import "../../custom.css";
import styles from "./create.module.css";

const PersonaInput = ({
  botFieldsForm,
  name,
  examples,
  value,
  setBotFieldsForm,
}) => {
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const newFieldForm = { ...botFieldsForm, [name]: e.target.value };
    setBotFieldsForm(newFieldForm);
  };

  useEffect(() => {
    if (name === "Name") {
      if (botFieldsForm[name]?.length < 3) {
        setError("Name must be at least 3 characters long");
      } else {
        setError("");
      }
    }
  }, [botFieldsForm, name]);

  return (
    <div className={styles.personaInput}>
      <span style={{ marginLeft: "6px" }}>{name}</span>
      <input
        value={value[name]}
        onChange={handleChange}
        placeholder={name ? examples : ""}
      />
      <p>{error}</p>
    </div>
  );
};

export default PersonaInput;
