import { format } from "date-fns";

export const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    const formattedDate = format(date, "h:mmaaa 'on' MMM d, yyyy");
    return formattedDate;
  } catch (error) {
    return "Invalid Date";
  }
};

export const messageAuthor = (suspectName, direction, senderName) => {
  if (direction === "IncomingToBot") return suspectName;
  return senderName || "bot";
};
