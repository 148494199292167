import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./utils/authConfig.js";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { saveConfiguration } from "./redux/slices/settings.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setIsLoggedIn } from "./redux/slices/auth.js";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Get the settings from the backend, needed for MSAL authentication (use retries in case the backend is still starting up)
const GetConfigSettings = (retries = 3) => {
  return fetch("/api/config")
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (retries > 0) {
        return GetConfigSettings(retries - 1);
      }
      throw new Error(response.status);
    })
    .catch((err) => {
      console.error("Unable to call /api/config endpoint: " + err);
    });
};

// We have to wait until the config is in place to load the rest
GetConfigSettings().then((msaldata) => {
  // write to the redux store so we have it for later
  store.dispatch(saveConfiguration(msaldata));

  // construct the MSAL instance we'll need for the rest of the app
  const msalInstance = new PublicClientApplication(msalConfig(msaldata));

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
      store.dispatch(setIsLoggedIn(true));

    }
  });

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  root.render(
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <App instance={msalInstance} />
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
