import React, { useEffect, useState } from "react";
import blur from "../../../../src/images/chatpageaccessdenied.png";
import blur2 from "../../../../src/images/chatpageNoData.png";
import { fetchWithAuth } from "../../../utils/authPopup.js";
import ChatInfo from "../ChatInfo.js";
import ChatList from "../ChatList/ChatList.js";
import ChatPanel from "../ChatPanel.js";

import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Icon } from "@iconify/react";

import useGetOperations from "../../../hooks/useGetOperations.jsx";
import {
  setFormattedSuspectData,
  setSuspectData,
} from "../../../redux/slices/suspect.js";
import useFetchChatConvos from "../hooks/useFetchChatConvos.jsx";
import useSignalR from "../hooks/useSignalR.jsx";
import convoSeen from "../utils/convoSeen.js";

import classes from "./ChatPage.module.css";

const ChatPage = ({ instance, organizationId }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [messageWasSent, setMessageWasSent] = useState(false);

  const isSmScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const VIEW_ENUM = { CHAT: "chat", INFO: "info", CHAT_LIST: "chatList" };
  const [view, setView] = useState(VIEW_ENUM.CHAT);

  const { getOperations } = useGetOperations(instance);
  const {
    fetchChatConvos,
    refetchConvo,
    addConvo,
    conversations,
    setConversations,
    selectedChat,
    setSelectedChat,
    hasMoreConvos,
    selectedOperation,
    setSelectedOperation,
    selectedBot,
    setSelectedBot,
    setSuspectPhoneNumber,
  } = useFetchChatConvos(instance);

  const { newMessage } = useSignalR(
    instance,
    conversations,
    setConversations,
    addConvo
  );

  useEffect(() => {
    if (!selectedChat) return;

    convoSeen(instance, setConversations, organizationId, selectedChat);
  }, [newMessage]);

  async function extraCall(msalContext) {
    try {
      const data = await fetchWithAuth(
        msalContext,
        "GET",
        `api/organization/${organizationId}/suspects`
      );

      const parsedData = data.map((item, index) => {
        // Parse the last updated date/time into a more readable format based on locale
        const lastUpdatedDateTime = new Date(item.lastUpdatedDate);
        const lastUpdatedDate = lastUpdatedDateTime.toLocaleDateString();
        const lastUpdatedTime = lastUpdatedDateTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        const operationList = item.operations.map((el) => el.name);
        const organizationLocations = item.operations.map((el) => {
          if (el.organizationId != organizationId) {
            return el.location;
          }
        });

        return {
          name: item.name,
          operationId: item.operations[0].id,
          operationListNames: operationList,
          formattedPhoneNumber: item.formattedPhoneNumber,
          organizationLocations: organizationLocations,
          operation: item.operations[0].name,
          risk: item.suspectRiskLevel,
          phoneNumber: item.phoneNumber,
          carrier: item.properties.Carrier,
          ageRange: item.properties.AgeRange,
          totalMessages: "TBD",
          id: item.id, // TODO: Use suspectId from fetched data. However, it is currently all 0s, which needs to be fixed
          totalBotsContacted: item.botIds.length,
          lastContactedDate: lastUpdatedDate,
          lastContactedTime: lastUpdatedTime,
          notes: item.notes,
          totalOperationCount: item.operationIds.length,
          totalOperations: item.operations,
          orgId: item.organizationId,
          city: item.whitePages?.address?.city,
          country: item.whitePages?.address?.country,
          state: item.whitePages?.address?.state,
          street: item.whitePages?.address?.street,
          unit: item.whitePages?.address?.unit,
          zipCode: item.whitePages?.address?.zipCode,
          age: item.whitePages?.age,
          email: item.whitePages?.email,
          firstName: item.whitePages?.firstName,
          lastName: item.whitePages?.lastName,
          lastReportedCallerIdInfo: item.whitePages?.lastReportedCallerIdInfo,
          lastUpdatedCallerId: item.whitePages?.lastUpdatedCallerId,
          lastUpdatedCriminalSearch: item.whitePages?.lastUpdatedCriminalSearch,
          middleName: item.whitePages?.middleName,
          phoneNumber: item.whitePages?.phoneNumber,
          phoneType: item.whitePages?.phoneType,
          therapeuticMessageSent: item.whitePages?.therapeuticMessageSent,
        };
      });

      dispatch(setFormattedSuspectData(parsedData));
      dispatch(setSuspectData(data));
    } catch (error) {
      if (error == "Forbidden") {
        setError(true);
      }
    }
  }

  useEffect(() => {
    getOperations();
    extraCall(instance);
  }, [organizationId, messageWasSent]);

  return (
    <div>
      {error ? (
        <img style={{ width: "98%", marginLeft: "10px" }} src={blur} />
      ) : conversations !== null ? (
        <div className={classes.chatPageContainer}>
          <div
            style={{
              display:
                view === VIEW_ENUM.CHAT_LIST || !isSmScreen ? "block" : "none",
            }}
          >
            <ChatList
              organizationId={organizationId}
              instance={instance}
              conversations={conversations}
              setConversations={setConversations}
              fetchChatConvos={fetchChatConvos}
              hasMoreConvos={hasMoreConvos}
              selectedOperation={selectedOperation}
              setSelectedOperation={setSelectedOperation}
              selectedBot={selectedBot}
              setSelectedBot={setSelectedBot}
              setSuspectPhoneNumber={setSuspectPhoneNumber}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              setView={setView}
            />
          </div>

          <div
            className={classes.chatPanelContainer}
            style={{
              display:
                view === VIEW_ENUM.CHAT || !isSmScreen ? "block" : "none",
            }}
          >
            <div className="d-flex justify-content-between">
              <div
                className={classes.mobileNavButton}
                onClick={() => setView(VIEW_ENUM.CHAT_LIST)}
              >
                <Icon icon="bx:arrow-back" />
                Back
              </div>
              <div
                className={classes.mobileNavButton}
                onClick={() => setView(VIEW_ENUM.INFO)}
              >
                <Icon icon="material-symbols:info-outline" />
                Info
              </div>
            </div>
            <ChatPanel
              setMessageWasSent={setMessageWasSent}
              instance={instance}
              organizationId={organizationId}
              conversations={conversations}
              setConversations={setConversations}
              selectedChat={selectedChat}
              refetchConvo={refetchConvo}
            />
          </div>

          <div
            style={{
              display:
                view === VIEW_ENUM.INFO || !isSmScreen ? "block" : "none",
            }}
          >
            <div
              className={classes.mobileNavButton}
              onClick={() => setView(VIEW_ENUM.CHAT)}
            >
              <Icon icon="bx:arrow-back" />
              Back
            </div>
            <ChatInfo
              selectedChat={selectedChat}
              key={selectedChat?.messages?.[0]?.date}
            />
          </div>
        </div>
      ) : (
        <img style={{ width: "98%", marginLeft: "10px" }} src={blur2} />
      )}
    </div>
  );
};

export default ChatPage;
