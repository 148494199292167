import styled from "styled-components";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export const OpsContainer = styled.div`
  margin: 0em 3em 2em 3em;
  font-family: "Roboto", Helvetica;
`;

export const ListHeader = styled.ul`
  list-style: "";
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const ListValue = styled.ul`
  list-style: "";
  padding-left: 1em;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const OpsDashboardButton = styled(Button)`
  --bs-btn-font-size: 35px;
  --bs-btn-border-color: "transparent";
  color: "#AAAAAA";
`;

export const BackLink = styled(Link)`
  font-size: 18px;
`;
