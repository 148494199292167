import { createSlice } from "@reduxjs/toolkit";

const LOCAL_STORAGE_KEYS = {
  SELECTED_ORG: "selectedOrg",
};

const getLocalStorageItem = (key, defaultValue = false) => {
  const item = localStorage.getItem(key);

  if (!item) return defaultValue;

  try {
    return JSON.parse(item);
  } catch (error) {
    return defaultValue;
  }
};

const initialState = {
  data: [],
  selectedOrg: getLocalStorageItem(LOCAL_STORAGE_KEYS.SELECTED_ORG),
  selectedSuspect: false,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrgData(state, action) {
      state.data = action.payload;
    },
    setSelectedOrg(state, action) {
      state.selectedOrg = action.payload;
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SELECTED_ORG,
        JSON.stringify(action.payload)
      );
    },
  },
});

export const { setOrgData, setSelectedOrg } = organizationSlice.actions;
export default organizationSlice.reducer;
