import React from "react";
import { Button, Modal, Dropdown, Form, DropdownButton } from "react-bootstrap";
import { useState } from "react";
import { fetchWithAuth } from "../utils/authPopup";
import { useDispatch, useSelector } from "react-redux";
import { setOrgData, setSelectedOrg } from "../redux/slices/organization";
import LoadingButton from "./shared/LoadingButton";

function NewUser({ instance, show, openModal, closeModal }) {
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: false,
  });
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleAdmin(e) {
    if (e.target.name === "false") {
      setFormData({ ...formData, isAdmin: false });
    } else if ((e.target.name = "true")) {
      setFormData({ ...formData, isAdmin: true });
    } else {
      return null;
    }
  }

  const { firstName, lastName, email, isAdmin } = formData;

  const createNewUser = async () => {
    setLoading(true);

    try {
      const newUser = { ...formData, organizationId: selectedOrg.id };

      await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${selectedOrg.id}/user`,
        newUser
      );

      const data = await fetchWithAuth(instance, "GET", "api/organization");

      dispatch(setOrgData(data));
      dispatch(
        setSelectedOrg(data.find((org) => org.id === selectedOrg.id) || data[0])
      );

      closeModal();
    } catch (error) {
      console.error("Error creating new user:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center"></div>

      <Modal show={openModal} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Create New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="form-title">Name: </Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}
              name="firstName"
              value={firstName}
              placeholder="Enter your user's first name..."
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-title">Last Name: </Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}
              name="lastName"
              value={lastName}
              placeholder="Enter your user's last name..."
            />
            <Form.Label className="form-title">Email: </Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}
              name="email"
              value={email}
              placeholder="Enter your user's email address..."
            />
            <Form.Label className="form-title">Permission Level: </Form.Label>
            <Dropdown>
              <DropdownButton
                id="dropdown-basic"
                title={isAdmin ? "Administrator" : "User"}
                className="modal-box"
              >
                {isAdmin ? (
                  <Dropdown.Item
                    onClick={handleAdmin}
                    name="false"
                    title="User"
                  >
                    User
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={handleAdmin}
                    name="true"
                    title="Administrator"
                  >
                    Administrator
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </Dropdown>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Cancel</Button>
          <LoadingButton onClick={createNewUser} loading={loading}>
            Submit
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewUser;
