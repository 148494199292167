import styled from "styled-components";
import { Table } from "reactstrap";
import { Pagination } from "react-bootstrap";

export const OpsTableContainer = styled.div`
  margin-top: 2em;
`;

export const OpsTable = styled(Table)`
  border-radius: 100px;
  --bs-table-bg: #262944;
  --bs-table-color: #ffffff;
  --bs-table-border: none;
  --bs-table-border-color: gray;
  border-width: 5px;
  --bs-table-border-radius: 100px;

  thead th {
    cursor: pointer;
    user-select: none;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  tbody td {
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
  }
`;

export const TablePagination = styled.div`
  font-size: 16px;
`;

export const TableFilter = styled.input`
  outline: none;
  border: none;
  border-bottom: 2px solid #aaaaaa;
  background-color: transparent;
  color: #aaaaaa;
  width: 7em;
`;

export const PaginationButton = styled(Pagination)`
  user-select: none;
  --bs-pagination-color: #fff;
  --bs-pagination-bg: transparent;
  --bs-pagination-hover-color: black;
`;

export const OpsName = styled.div`
  text-align: left;
  padding-left: 2em;
`;
