import { CardTitle, CardText } from "reactstrap";
import { useSelector } from "react-redux";
import { InfoCard } from "../styles/Card.styled";

/**
 * Card for displaying information in a list format.
 * @param {string} [title=null] - The title of the card
 * @param {string} [subtitle] - A subtitle for the card
 * @param {ReactNode} [content] - The content to render in the card
 * @param {string} [button] - The text to display on the button
 * @returns {JSX.Element} - The ListCard component
 */
export default function ListCard({ title, subtitle, content, button }) {
  const theme = useSelector((state) => state.theme);

  return (
    <InfoCard className="p-4 pb-0" body theme={theme}>
      <div className="d-flex flex-sm-column flex-lg-row  ">
        <CardTitle tag="h3">{title}</CardTitle>
        {subtitle ? (
          <p className=" ms-sm-0  ms-md-0 ms-lg-3 ms-3">{subtitle}</p>
        ) : (
          <></>
        )}
      </div>
      <CardText>{content}</CardText>
    </InfoCard>
  );
}
