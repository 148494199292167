import React, { useState, useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import {
  ChatInfoContainer,
  ChatInfoHeader,
  ChatInfoGroupContainer,
  ChatInfoItem,
  ChatInfoLabel,
  ChatNotesContainer,
} from "./ChatInfo.styled.js";
import Card from "react-bootstrap/Card";
import { formatDate } from "./utils/messageFormat.js";
import { useSelector } from "react-redux";

const ChatInfo = ({ selectedChat }) => {
  const theme = useSelector((state) => state.theme);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);

  const [chatInfo, setChatInfo] = useState({});

  useEffect(() => {
    setChatInfo({});
  }, [selectedOrg]);

  useEffect(() => {
    if (selectedChat) {
      setChatInfo({
        status: selectedChat?.status || "",
        suspectPhoneNumber: selectedChat?.suspectPhoneNumber || "",
        name: selectedChat?.name || "",
        firstMessage: selectedChat?.dateTime || "",
        botName: selectedChat?.botName || "",
        totalMessages: selectedChat?.length || "",
        firstMessageDate: selectedChat?.firstMessageDate || "",
        takeoverTime: selectedChat?.takeoverTime || "",
        takeoverUserName: selectedChat?.takeoverUserName || "",
        person: {
          city: selectedChat?.suspect?.whitePages?.address?.city || "",
          country: selectedChat?.suspect?.whitePages?.address?.country || "",
          state: selectedChat?.suspect?.whitePages?.address?.state || "",
          street: selectedChat?.suspect?.whitePages?.address?.street || "",
          unit: selectedChat?.suspect?.whitePages?.address?.unit || "",
          zipCode: selectedChat?.suspect?.whitePages?.address?.zipCode || "",
          age: selectedChat?.suspect?.whitePages?.age || "",
          email: selectedChat?.suspect?.whitePages?.email || "",
          firstName: selectedChat?.suspect?.whitePages?.firstName || "",
          lastName: selectedChat?.suspect?.whitePages?.lastName || "",
          lastReportedCallerIdInfo:
            selectedChat?.suspect?.whitePages?.lastReportedCallerIdInfo || "",
          lastUpdatedCallerId:
            selectedChat?.suspect?.whitePages?.lastUpdatedCallerId || "",
          lastUpdatedCriminalSearch:
            selectedChat?.suspect?.whitePages?.lastUpdatedCriminalSearch || "",
          middleName: selectedChat?.suspect?.whitePages?.middleName || "",
          phoneNumber: selectedChat?.suspect?.whitePages?.phoneNumber || "",
          phoneType: selectedChat?.suspect?.whitePages?.phoneType || "",
          therapeuticMessageSent:
            selectedChat?.suspect?.whitePages?.therapeuticMessageSent || "",
        },
      });
    }
  }, [selectedChat]);

  const handleTime = (time) =>
    time ? new Date(time).toLocaleTimeString() : "";

  const handleDate = (time) => {
    if (!time) return "";
    const date = new Date(time);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <ChatInfoContainer theme={theme}>
      <ChatInfoHeader>Conversation Info</ChatInfoHeader>
      <ChatInfoGroupContainer variant="flush">
        {chatInfo.status && (
          <ChatInfoItem>
            <ChatInfoLabel>Status: {chatInfo.status}</ChatInfoLabel>
            <ChatInfoLabel>
              Total Messages: {chatInfo.totalMessages}
            </ChatInfoLabel>
            <ChatInfoLabel>
              First Message: {handleTime(chatInfo.firstMessageDate)} /{" "}
              {handleDate(chatInfo.firstMessageDate)}
            </ChatInfoLabel>
            {chatInfo.status === "Takeover" && (
              <>
                <ChatInfoLabel>
                  Takeover: {formatDate(chatInfo.takeoverTime)}
                </ChatInfoLabel>
                <ChatInfoLabel>
                  Takeover By: {chatInfo.takeoverUserName}
                </ChatInfoLabel>
              </>
            )}
          </ChatInfoItem>
        )}
        <hr style={{ margin: "15px 0", height: "1px" }} />
        <ChatInfoHeader>Bot learned info</ChatInfoHeader>
        <ChatInfoItem>Coming Soon</ChatInfoItem>
        <hr style={{ margin: "15px 0", height: "1px" }} />
        <ChatInfoHeader>White pages info</ChatInfoHeader>
        {chatInfo.person?.firstName && (
          <ChatInfoItem>
            <div>
              <ChatInfoLabel>
                Name: {chatInfo.person.firstName} {chatInfo.person.middleName}{" "}
                {chatInfo.person.lastName}
              </ChatInfoLabel>
            </div>
            <div>
              <ChatInfoLabel>
                Phone Number: {chatInfo.suspectPhoneNumber}
              </ChatInfoLabel>
            </div>
            {chatInfo.person.age && (
              <div>
                <ChatInfoLabel>Age: {chatInfo.person.age}</ChatInfoLabel>
              </div>
            )}
            {chatInfo.person.email && (
              <div>
                <ChatInfoLabel>Email: {chatInfo.person.email}</ChatInfoLabel>
              </div>
            )}
            {(chatInfo.person.street ||
              chatInfo.person.city ||
              chatInfo.person.state ||
              chatInfo.person.zipCode ||
              chatInfo.person.country) && (
              <div>
                <ChatInfoLabel>
                  Address: {chatInfo.person.street},{" "}
                  {chatInfo.person.unit ? chatInfo.person.unit + ", " : ""}
                  {chatInfo.person.city}, {chatInfo.person.state}{" "}
                  {chatInfo.person.zipCode}, {chatInfo.person.country}
                </ChatInfoLabel>
              </div>
            )}
            {chatInfo.person.phoneType && (
              <div>
                <ChatInfoLabel>
                  Phone Type: {chatInfo.person.phoneType}
                </ChatInfoLabel>
              </div>
            )}
            {chatInfo.person.lastReportedCallerIdInfo && (
              <div>
                <ChatInfoLabel>
                  Last Reported Caller ID Info:{" "}
                  {chatInfo.person.lastReportedCallerIdInfo}
                </ChatInfoLabel>
              </div>
            )}
            {chatInfo.person.lastUpdatedCallerId && (
              <div>
                <ChatInfoLabel>
                  Last Updated Caller ID: {chatInfo.person.lastUpdatedCallerId}
                </ChatInfoLabel>
              </div>
            )}
            {chatInfo.person.therapeuticMessageSent && (
              <div>
                <ChatInfoLabel>
                  Therapeutic Message Sent:{" "}
                  {chatInfo.person.therapeuticMessageSent ? "Yes" : "No"}
                </ChatInfoLabel>
              </div>
            )}
          </ChatInfoItem>
        )}
        <hr style={{ margin: "15px 0", height: "1px" }} />
        <ChatInfoHeader>Suspect Note:</ChatInfoHeader>
        <ChatInfoItem>
          <ChatNotesContainer theme={theme}>
            <Card.Text>...</Card.Text>
          </ChatNotesContainer>
        </ChatInfoItem>
        <hr style={{ margin: "15px 0", height: "1px" }} />
        <ChatInfoHeader>Tags</ChatInfoHeader>
        <Badge pill bg="secondary" style={{ margin: "15px 0 10px 10px" }}>
          + Add
        </Badge>
        <hr style={{ margin: "15px 0", height: "1px" }} />
        <ChatInfoHeader>...</ChatInfoHeader>
        <ChatInfoItem></ChatInfoItem>
      </ChatInfoGroupContainer>
    </ChatInfoContainer>
  );
};

export default ChatInfo;
