import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useSelector } from "react-redux";
import { CDBContainer } from "cdbreact";
import { CategoryScale, LinearScale, BarElement } from "chart.js";
import noSuspects from "./No data charts/no-suspects.png";
ChartJS.register(CategoryScale, LinearScale, BarElement);
ChartJS.register(ArcElement, Tooltip, Legend);

const Suspects = ({ suspectsData }) => {
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";

  const [riskCount, setRiskCount] = useState(false);
  const [data] = useState({
    datasets: [
      {
        borderColor: "white",
        data: [
          suspectsData.lowRiskSuspectCount,
          suspectsData.mediumRiskSuspectCount,
          suspectsData.highRiskSuspectCount,
        ],
      },
    ],
  });

  function riskCounter() {
    const totalRisk =
      suspectsData.highRiskSuspectCount +
      suspectsData.mediumRiskSuspectCount +
      suspectsData.lowRiskSuspectCount;
    setRiskCount(totalRisk);
  }

  useEffect(() => {
    riskCounter();
  }, [suspectsData]);

  function displayData() {
    if (data.datasets[0].data.length === 0 || riskCount === 0) {
      return "no-data";
    } else {
      return "chart-covers";
    }
  }

  return (
    <div className={`${themeClass} ${displayData()}`}>
      {data.datasets[0].data.length === 0 || riskCount === 0 ? (
        <div className="nodataImg">
          <img src={noSuspects} />
        </div>
      ) : (
        <CDBContainer className="drogba">
          <h5
            className="chart-title"
            style={{
              borderColor: theme === "light" ? "#0a0e37" : "",
            }}
          >
            Suspects
          </h5>
          <h1> {riskCount}</h1>
          <Bar height={230} data={data} />
        </CDBContainer>
      )}
    </div>
  );
};

export default Suspects;
