import styled from "styled-components";

export const SearchBarContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const SearchBar = styled.input`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#282C4D"};
  border: none;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  color: white;
`;
