import React from "react";
import { Button, Modal, Dropdown, Form } from "react-bootstrap";
import { useState } from "react";
import { fetchWithAuth } from "../utils/authPopup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOrgData, setSelectedOrg } from "../redux/slices/organization";
import LoadingButton from "./shared/LoadingButton";
import handleError from "../utils/handleError";

const NewOrg = ({ instance, show, openModal, closeModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgData = useSelector((state) => state.organization.data);
  const [loading, setLoading] = useState(false);
  const [newOrgData, setNewOrgData] = useState({
    name: "",
    primaryContactName: "",
    primaryContactTitle: "",
    primaryContactPhoneNumber: "",
  });
  const [newOrgType, setNewOrgType] = useState("");
  const orgTypes = [
    "Local Law Enforcement",
    "State Law Enforcement",
    "Federal Law Enforcement",
    "Non-Profit Organization",
    "Research Group",
    "University",
    "For-Profit Organization",
    "Demo",
    "Other",
  ];

  function handleNewOrg(e) {
    const { name, value } = e.target;
    setNewOrgData({ ...newOrgData, [name]: value });
  }

  function handleNewOrgType(e) {
    setNewOrgType(e);
  }

  const {
    name,
    primaryContactName,
    primaryContactTitle,
    primaryContactPhoneNumber,
  } = newOrgData;

  const createNewOrg = async () => {
    setLoading(true);
    try {
      const data = await fetchWithAuth(instance, "POST", "api/organization", {
        name: newOrgData.name,
        type: newOrgType,
        primaryContact: {
          name: primaryContactName,
          title: primaryContactTitle,
          phoneNumber: primaryContactPhoneNumber,
        },
      });
      closeModal();

      const newOrgs = [...orgData];
      newOrgs.push(data);

      dispatch(setOrgData(newOrgs));
      dispatch(setSelectedOrg(data));
      navigate("/edit-organization");
    } catch (error) {
      handleError("Error when creating a new organization");
      console.error("Error creating new organization:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center"></div>

      <Modal show={openModal} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Create new organization
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="form-title">Name: </Form.Label>
            <Form.Control
              type="text"
              onChange={handleNewOrg}
              name="name"
              value={name}
              placeholder="Enter your new organization's name..."
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-title">Type: </Form.Label>
            <Dropdown onSelect={handleNewOrgType}>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="modal-box"
              >
                {newOrgType ? newOrgType : "Select an organization type"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {orgTypes.map((type) => (
                  <Dropdown.Item key={type} eventKey={type}>
                    {type}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Primary Contact Name:</Form.Label>
            <Form.Control
              type="text"
              onChange={handleNewOrg}
              name="primaryContactName"
              value={primaryContactName}
              placeholder="Enter your primary contact's name..."
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Primary Contact Title:</Form.Label>
            <Form.Control
              type="text"
              onChange={handleNewOrg}
              name="primaryContactTitle"
              value={primaryContactTitle}
              placeholder="Enter your primary contact's title..."
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Primary Contact Phone Number:</Form.Label>
            <Form.Control
              type="text"
              onChange={handleNewOrg}
              name="primaryContactPhoneNumber"
              value={primaryContactPhoneNumber}
              placeholder="Enter your primary contact's phone number..."
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Cancel</Button>
          <LoadingButton onClick={createNewOrg} loading={loading}>
            Submit
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewOrg;
