import React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";

function Layout(props) {
  return (
    <div>
      <NavMenu
        superAdminShow={props.superAdminShow}
        setIsLoggedIn={props.setIsLoggedIn}
        instance={props.instance}
        orgData={props.orgData}
        setOrgData={props.setOrgData}
        selectedOrg={props.selectedOrg}
        setSelectedOrg={props.setSelectedOrg}
      />
      {props.children}
    </div>
  );
}

export default Layout;
