import React from "react";
import MapChart from "./MapChart";
import PublicInfo from "./PublicInfo";

import styles from "./Welcome.module.css";
import WelcomeVideo from "./components/WelcomeVideo";

const Welcome = () => {
  return (
    <div className={styles.welcome}>
      {/* <h1 className={styles.message}>
        Welcome to the Transaction Intercept platform!
      </h1> */}
      {/* <MapChart /> */}
      <WelcomeVideo />
      {/* <PublicInfo /> */}
    </div>
  );
};

export default Welcome;
