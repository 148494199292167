import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { CDBContainer } from "cdbreact";
import { useSelector } from "react-redux";
import noMessages from "./No data charts/no-messages.png";

const Messages = ({ messagesData }) => {
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";
  const color = theme === "light" ? "#15135b" : "white";

  const [data] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Messages",
        data: [1, 2, 3, 4, 5, 6, 7, 8],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  });

  function displayData() {
    if (messagesData.messageCount === 0) {
      return "no-data";
    } else {
      return "chart-covers";
    }
  }

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: false,
          maxTicksLimit: 0,
          color,
        },
        grid: {
          color,
        },
        title: {
          display: true,
          text: "Time",
          color,
        },
      },
      y: {
        ticks: {
          display: false,
          maxTicksLimit: 0,
          color,
        },
        grid: {
          color,
        },
        title: {
          display: true,
          text: "# of Messages",
          color,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color,
        },
      },
    },
  };

  return (
    <div className={`${themeClass} ${displayData()}`}>
      {messagesData.messageCount === 0 ? (
        <div className="nodataImg">
          <img src={noMessages} />
        </div>
      ) : (
        <CDBContainer className="drogba">
          <h5
            className="chart-title"
            style={{
              borderColor: theme === "light" ? "#0a0e37" : "",
            }}
          >
            Messages
          </h5>
          {data.datasets[0].data.length !== 0 ? (
            <h1>{messagesData.messageCount}</h1>
          ) : (
            <h1>1,321</h1>
          )}
          <Line height={230} data={data} options={options} />
        </CDBContainer>
      )}
    </div>
  );
};

export default Messages;
