import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

function Protected({ children }) {
  const { accounts } = useMsal();
  const isLoggedIn = accounts.length > 0;

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}

export default Protected;