import styled from "styled-components";
import { Form } from "react-bootstrap";

export const SuspectNotesForm = styled(Form)`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

export const SuspectNotesTextArea = styled(Form.Control)`
    resize: none;
    flex-grow: 1;
    background-color: #0A0E37;
    color: #FFFFFF;
`;