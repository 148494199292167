import React, { useEffect, useState } from "react";
import { fetchWithAuth } from "./utils/authPopup.js";
import { Route, Routes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import Layout from "./pages/nav/Layout.js";
import Home from "./pages/Organizations/Home.js";
import "./custom.css";
import UniversalNav from "./pages/nav/UniversalNav";
import { AuthenticatedTemplate } from "@azure/msal-react";
import EditOrganization from "./pages/Organizations/EditOrganization.js";
import Welcome from "./pages/Welcome/Welcome.js";
import OperationsList from "./pages/OperationsList/OperationsList.js";
import OperationDashboard from "./pages/OperationDashboard/OperationDashboard.js";
import BotDisplay from "./pages/Bots/BotDisplay.js";
import ChatPage from "./components/Chats/ChatPage/ChatPage.js";
import CreateBots from "./pages/Bots/CreateBots.js";
import EditBot from "./pages/Bots/EditBot.js";
import SuspectProfile from "./pages/Suspects/SuspectProfile.js";
import SuspectsList from "./pages/Suspects/SuspectsList.js";
import NotFound from "./components/shared/NotFound.js";
import Protected from "./components/shared/Protected.js";
import EditOperation from "./pages/EditOperation/EditOperation.js";
import { useDispatch, useSelector } from "react-redux";
import { setOrgData, setSelectedOrg } from "./redux/slices/organization.js";
import { setActiveAccount, setIsLoggedIn } from "./redux/slices/auth.js";
import Platformdata from "./pages/Platformdata/Platformdata.js";
import Help from "./pages/HelpPage/Help.js";

function App({ instance }) {
  const [superAdminShow, setSuperAdminShow] = useState(false);
  const [orgAdminShow, setOrgAdminShow] = useState(false);
  const dispatch = useDispatch();
  const activeAccount = useSelector((state) => state.auth.activeAccount);
  const orgData = useSelector((state) => state.organization.data);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  useEffect(() => {
    // Fetch user's organization data on page load
    const fetchOrgData = async () => {
      if (instance.getActiveAccount() != null || undefined) {
        const response = await fetchWithAuth(
          instance,
          "GET",
          "api/organization"
        );

        dispatch(setOrgData(response));
        if (selectedOrg === false) {
          dispatch(setSelectedOrg(response[0]));
        }
      }
    };

    // Validate that the user is logged in on page load
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && activeAccount != currentAccount) {
      dispatch(setActiveAccount(currentAccount));
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
      return;
    }
    fetchOrgData();
  }, [instance, dispatch, isLoggedIn]);

  const checkSuper = async () => {
    const data2 = await fetchWithAuth(
      instance,
      "POST",
      "api/organization/?CheckAccess=True",
      {}
    );

    return data2;
  };

  const checkOrganizationAdmin = async () => {
    const data2 = await fetchWithAuth(
      instance,
      "PUT",
      `api/organization/${selectedOrg.id}?CheckAccess=True`,
      {}
    );

    return data2;
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkSuper()
        .then((response) => {
          if (response.status === 200) {
            setSuperAdminShow(true);
          }
        })
        .catch((error) => {
          setSuperAdminShow(false);
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      checkOrganizationAdmin()
        .then((response) => {
          if (response.status === 200) {
            setOrgAdminShow(true);
          }
        })
        .catch((error) => {
          setOrgAdminShow(false);
        });
    }
  }, [isLoggedIn, selectedOrg]);

  return (
    <MsalProvider instance={instance}>
      <Layout
        superAdminShow={superAdminShow}
        orgData={orgData}
        selectedOrg={selectedOrg}
      >
        <AuthenticatedTemplate>
          <UniversalNav />
          <br></br>
        </AuthenticatedTemplate>
        <Routes>
          {isLoggedIn ? (
            <Route
              index
              element={
                <Protected>
                  <Home activeAccount={activeAccount} instance={instance} />
                </Protected>
              }
            />
          ) : (
            <Route index element={<Welcome />} />
          )}
          <Route
            path="edit-organization"
            element={
              <Protected>
                <EditOrganization
                  superAdminShow={superAdminShow}
                  orgAdminShow={orgAdminShow}
                  instance={instance}
                />
              </Protected>
            }
          />{" "}
          <Route path="operations">
            <Route
              index={true}
              element={
                <Protected>
                  <OperationsList instance={instance} />
                </Protected>
              }
            ></Route>
            <Route
              path="operation/:id"
              element={
                <Protected>
                  <OperationDashboard
                    instance={instance}
                    orgId={selectedOrg.id}
                  />
                </Protected>
              }
            />
            <Route
              path="edit-operation/:id"
              element={
                <Protected>
                  <EditOperation instance={instance} orgId={selectedOrg.id} />
                </Protected>
              }
            />
          </Route>
          <Route path="suspects">
            <Route
              index={true}
              element={
                <Protected isLoggedIn={isLoggedIn}>
                  <SuspectsList instance={instance} idd={selectedOrg.id} />
                </Protected>
              }
            />
            <Route
              path="suspect-profile/:suspectId"
              element={
                <Protected isLoggedIn={isLoggedIn}>
                  <SuspectProfile
                    instance={instance}
                    organizationId={selectedOrg.id}
                  />{" "}
                </Protected>
              }
            />
          </Route>
          <Route path="bots">
            <Route
              index={true}
              element={
                <Protected>
                  <BotDisplay
                    instance={instance}
                    organizationId={selectedOrg.id}
                  />
                </Protected>
              }
            ></Route>

            <Route
              path="create-bot"
              element={
                <Protected>
                  <CreateBots
                    instance={instance}
                    organizationId={selectedOrg.id}
                  />
                </Protected>
              }
            />
            <Route
              path="edit-bot/:id"
              element={
                <Protected>
                  <EditBot
                    instance={instance}
                    organizationId={selectedOrg.id}
                  />
                </Protected>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <Protected>
                <NotFound />
              </Protected>
            }
          />
          <Route
            path="chats"
            element={
              <ChatPage instance={instance} organizationId={selectedOrg.id} />
            }
          />{" "}
          <Route
            path="help"
            element={
              <Help instance={instance} organizationId={selectedOrg.id} />
            }
          />{" "}
          <Route path="platformdata">
            <Route
              index={true}
              element={
                <Protected>
                  <Platformdata instance={instance} idd={selectedOrg.id} />
                </Protected>
              }
            />
          </Route>
        </Routes>
      </Layout>
    </MsalProvider>
  );
}

export default App;
