import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useSelector } from "react-redux";
import { CDBContainer } from "cdbreact";
import noOperations from "./No data charts/no-operations.png";
ChartJS.register(ArcElement, Tooltip, Legend);

const Operations = ({ operationsData, blur }) => {
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";

  const inProgressCount = operationsData.inProgressCount;
  const completedCount = operationsData.completedCount;
  const [data, setData] = useState({
    labels: ["In Progress", "Completed"],
    datasets: [
      {
        label: "",

        backgroundColor: ["grey", "teal"],
        borderColor: "white",
        data: [inProgressCount, completedCount],
      },
    ],
  });
  useEffect(() => {
    setData({
      labels: ["In Progress", "Completed"],
      datasets: [
        {
          label: "",

          backgroundColor: ["grey", "teal"],
          borderColor: "white",
          data: [inProgressCount, completedCount],
        },
      ],
    });
  }, [operationsData]);

  function displayData() {
    if (
      data.datasets[0].data.length === 0 ||
      (data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0)
    ) {
      return "no-data";
    } else {
      return "chart-covers";
    }
  }

  return (
    <div className={`${themeClass} ${displayData()}`}>
      {data.datasets[0].data.length === 0 ||
      (data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0) ? (
        <div className="nodataImg">
          <img src={noOperations} />
        </div>
      ) : (
        <CDBContainer className={`drogba`}>
          <h5
            className="chart-title"
            style={{
              borderColor: theme === "light" ? "#0a0e37" : "",
            }}
          >
            Operations
          </h5>
          <Doughnut
            data={data}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  labels: {
                    color: theme === "light" ? "#15135b" : "white",
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.label || "";
                      if (context.parsed.y !== null) {
                        label += ": " + context.parsed.y;
                      }
                      // Check if context.dataset.labels is defined before accessing
                      if (context.label && context.label === "Completed") {
                        label = operationsData?.completedOperationNames.map(
                          (el, idx) => `${idx + 1} . ${el.substring(0, 30)}...`
                        );
                      } else {
                        label = operationsData?.inProgressOperationNames.map(
                          (el, idx) => `${idx + 1} . ${el.substring(0, 30)}...`
                        );
                      }

                      return label;
                    },
                  },
                },
              },
            }}
          />
        </CDBContainer>
      )}
    </div>
  );
};

export default Operations;
