import React from "react";
import { useSelector } from "react-redux";
import OperationsTable from "../../components/OperationsTable/NewOpTable";
import AddOperation from "../../components/AddOperation/AddOperation";
import { OpsContainer } from "./OperationList.styled";
import Spinner from "react-bootstrap/Spinner";
import blur from "../../../src/images/blur_operation.png";
import blur2 from "../../../src/images/operationdashboardacessdenied.png";
import useGetOperations from "../../hooks/useGetOperations";

const OperationsList = ({ instance }) => {
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);

  const { getOperations, loading, error, blurState } =
    useGetOperations(instance);

  return (
    <OpsContainer>
      <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 justify-content-between">
        <div className="pt-4">
          <h1>All Operations</h1>
        </div>
        <div className="d-flex align-items-center">
          <AddOperation
            instance={instance}
            organizationId={selectedOrg.id}
            updateOpList={() => getOperations()}
          />
        </div>
      </div>
      {loading ? (
        <div className="LoadingIcon">
          <Spinner animation="border" />
        </div>
      ) : error ? (
        <img src={blur2} width={"100%"} alt="blur2" />
      ) : blurState?.length < 1 ? (
        <>
          <img src={blur} width={"100%"} alt="blur" />
        </>
      ) : (
        <div className="flex-grow-1 overflow-auto" style={{backgroundColor: '#282C4D'}}>
          <OperationsTable />
        </div>
      )}
    </OpsContainer>
  );
};

export default OperationsList;
