import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { fetchWithAuth } from "../../../utils/authPopup.js";
import ChatFilterSelectors from "../ChatFilterSelectors.js";
import convoSeen from "../utils/convoSeen.js";
import useListScroll from "../../../hooks/useListScroll.jsx";
import { ConversationType } from "../constants.js";

import classes from "./ChatList.module.css";

const ChatList = ({
  conversations,
  setConversations,
  fetchChatConvos,
  hasMoreConvos,
  selectedChat,
  setSelectedChat,
  instance,
  organizationId,
  selectedOperation,
  setSelectedOperation,
  selectedBot,
  setSelectedBot,
  setSuspectPhoneNumber,
  setView,
}) => {
  const theme = useSelector((state) => state.theme);
  const [selectedType, setSelectedType] = useState(ConversationType.TAKE_OVER);
  const [takeOverFilter, setTakeOverFilter] = useState(false);
  const [flagged, setFlaggedFilter] = useState(false);
  const [allConversation, setAllConversation] = useState(false);
  const [suspectSearch, setSuspectSearch] = useState("");
  const [onlyActiveOperations, setOnlyActiveOperations] = useState(false);
  const [filteredConversations, setFilteredConversations] = useState([
    ...conversations,
  ]);

  const { listRef, handleScroll } = useListScroll(
    fetchChatConvos,
    hasMoreConvos
  );

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSuspectPhoneNumber(suspectSearch);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [suspectSearch, setSuspectPhoneNumber]);

  const handleFilterChange = () => {
    setSelectedChat(null);
  };

  const onSwitchType = (type) => {
    setSelectedType(type);

    const filters = {
      [ConversationType.ALL]: {
        allConversation: true,
        flagged: false,
        takeOver: false,
      },
      [ConversationType.ALREADY_READ]: {
        allConversation: false,
        flagged: false,
        takeOver: false,
      },
      [ConversationType.FLAGGED]: {
        allConversation: false,
        flagged: true,
        takeOver: false,
      },
      [ConversationType.TAKE_OVER]: {
        allConversation: false,
        flagged: false,
        takeOver: true,
      },
    };

    const { allConversation, flagged, takeOver } =
      filters[type] || filters[ConversationType.TAKE_OVER];
    setAllConversation(allConversation);
    setFlaggedFilter(flagged);
    setTakeOverFilter(takeOver);
  };

  useEffect(() => {
    const filterByStatus = (item) =>
      onlyActiveOperations ? item.isOperationActive : true;
    const filterByTakeOver = (item) =>
      takeOverFilter ? item.humanTakeover : true;
    const filterByFlagged = (item) => (flagged ? item.flagged : true);
    const filterByAllConversation = (item) => allConversation !== true;

    const filteredChatItems = conversations?.filter(
      (item) =>
        filterByStatus(item) &&
        filterByTakeOver(item) &&
        filterByFlagged(item) &&
        filterByAllConversation(item)
    );

    setFilteredConversations(filteredChatItems);
  }, [
    allConversation,
    conversations,
    flagged,
    onlyActiveOperations,
    takeOverFilter,
  ]);

  const handleTime = (time) => {
    return new Date(time).toLocaleTimeString();
  };

  const handleFlag = async (itemId, item) => {
    const updatedConversations = conversations.map((conversation) =>
      conversation.id === itemId
        ? { ...conversation, flagged: !conversation.flagged }
        : conversation
    );

    setConversations(updatedConversations);

    await fetchWithAuth(
      instance,
      "PUT",
      `api/organization/${organizationId}/conversations/${itemId}/flag/${!item.flagged}`
    );
  };

  const handleConversationClick = async (item) => {
    setSelectedChat(item);
    setView("chat");

    if (item.unreadMessages === 0) return;

    convoSeen(instance, setConversations, organizationId, item);
  };

  return (
    <div
      className={`${classes.chatListContainer} ${classes[theme]}`}
      ref={listRef}
      onScroll={handleScroll}
    >
      <div className={`${classes.headerContainer}`}>
        <span>All</span>
        <input
          placeholder="Search..."
          onChange={(e) => setSuspectSearch(e.target.value)}
          value={suspectSearch}
        />
      </div>
      <ChatFilterSelectors
        selectedOperation={selectedOperation}
        setSelectedOperation={setSelectedOperation}
        selectedBot={selectedBot}
        setSelectedBot={setSelectedBot}
        onFilterChange={handleFilterChange}
        onlyActiveOperations={onlyActiveOperations}
        setOnlyActiveOperations={setOnlyActiveOperations}
        selectedType={selectedType}
        onSwitchType={onSwitchType}
      />
      <div className={classes.listGroupContainer} spacing={3}>
        {filteredConversations?.map((item, index) => {
          const boldStyle = {};

          if (item.unreadMessages > 0 && item.id !== selectedChat?.id) {
            boldStyle.fontWeight = "bold";
          }

          let itemColor;
          if (theme === "light") {
            itemColor = item.id === selectedChat?.id ? "#C3C2C6" : "#F4F3F8";
          } else if (theme === "dark") {
            itemColor = item.id === selectedChat?.id ? "#1C1F3C" : "#2A2F62";
          } else {
            itemColor = "#2A2F62";
          }

          return (
            <div key={index}>
              <div
                className={classes.listItemContainer}
                onClick={() => handleConversationClick(item)}
                style={{
                  backgroundColor: itemColor,
                }}
              >
                <span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      ...boldStyle,
                    }}
                  >
                    <Icon
                      onClick={() => handleFlag(item.id, item)}
                      icon={item.flagged ? "bxs:label" : "bx:label"}
                      style={{ marginRight: "8px" }}
                    />
                    {item.suspectPhoneNumber}
                  </span>
                  <div
                    style={{
                      fontSize: "0.6em",
                      color: "#AAAAAA",
                      ...boldStyle,
                    }}
                  >
                    {handleTime(item.lastMessageDate)}
                  </div>
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "0.8rem",
                    alignItems: "end",
                  }}
                >
                  <span style={boldStyle}>{item.botName}</span>
                  <span style={boldStyle}>{item.botNumber}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChatList;
