import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import { CDBContainer } from "cdbreact";
import noBots from "./No data charts/no-bots.png";

const Bots = ({ botsData, chartData }) => {
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";

  const [data, setData] = useState({
    labels: ["Closed", "Active"],
    datasets: [
      {
        label: "",
        backgroundColor: ["teal", "grey"],
        borderColor: "white",
        data: [botsData.closedBotsCount, botsData.activeBotsCount],
      },
    ],
  });

  useEffect(() => {
    setData({
      labels: ["Closed", "Active"],
      datasets: [
        {
          label: "",
          backgroundColor: ["teal", "grey"],
          borderColor: "white",
          data: [botsData.closedBotsCount, botsData.activeBotsCount],
        },
      ],
    });
  }, [botsData]);

  function displayData() {
    if (
      data.datasets[0].data.length === 0 ||
      (data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0)
    ) {
      return "no-data";
    } else {
      return "chart-covers";
    }
  }

  return (
    <div className={`${themeClass} ${displayData()}`}>
      {data.datasets[0].data.length === 0 ||
      (data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0) ? (
        <div className="nodataImg">
          <img src={noBots} />
        </div>
      ) : (
        <CDBContainer className="drogba">
          <div>
            <h5
              className="chart-title"
              style={{
                borderColor: theme === "light" ? "#0a0e37" : "",
              }}
            >
              Bots
            </h5>
            <Doughnut
              data={data}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    labels: {
                      color: theme === "light" ? "#15135b" : "white",
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";
                        if (context.parsed.y !== null) {
                          label += ": " + context.parsed.y;
                        }
                        // Check if context.dataset.labels is defined before accessing
                        if (context.label && context.label === "Closed") {
                          label = botsData?.closedBotNames.map(
                            (el, idx) =>
                              `${idx + 1} . ${el.substring(0, 30)}...`
                          );
                        } else {
                          label = botsData?.activeBotNames.map(
                            (el, idx) =>
                              `${idx + 1} . ${el.substring(0, 30)}...`
                          );
                        }

                        return label;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </CDBContainer>
      )}
    </div>
  );
};

export default Bots;
