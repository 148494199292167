import { CardTitle, CardText } from "reactstrap";
import { InfoCard } from "../styles/Card.styled";
import { useSelector } from "react-redux";

/**
 * Card for displaying information with numerical data.
 * @param {string} [title] - The title of the card
 * @param {number} [count] - The numerical data to display
 * @param {ReactNode} [content] - The content to render in the card
 * @param {ReactNode} [subcontent] - The subcontent to render in the card
 * @param {string} [button] - The text to display on the button
 * @param {Object} [cardStyle] - The style object for the card if needed
 * @returns {JSX.Element} - The NumCard component
 */
export default function NumCard({
  title = null,
  count = null,
  content = null,
  subcontent = null,
  button = null,
  cardStyle = {},
}) {
  const theme = useSelector((state) => state.theme);

  return (
    <InfoCard className="p-4 pb-0" body style={cardStyle} theme={theme}>
      <CardTitle className="pb-2" tag="h3">
        {title}
      </CardTitle>
      <CardText className="mb-0 d-flex">
        <div
          style={{ width: "100%" }}
          className="mt-2 d-flex flex-sm-column flex-md-column flex-lg-row justify-content-between  "
        >
          <CardTitle
            className="mb-5 mr-6  font-size-sm font-size-md-base font-size-lg-lg"
            tag="h1"
          >
            {count}
          </CardTitle>

          <div className="ms-lg-5 ms-sm-10 ">{content}</div>
        </div>
      </CardText>
    </InfoCard>
  );
}
