import React, { useState, useEffect } from "react";

import classes from "./WelcomeVideo.module.css";
import NumberSection from "./NumberSection";

const WelcomeVideo = () => {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const getVideoUrl = async () => {
      const responce = await fetch(`api/GetMediaUrl/welcome/welcome-map.mp4`);

      const data = await responce.json();

      setVideoUrl(data.url);
    };
    getVideoUrl();
  }, []);

  return (
    <div className={classes.welcomeVideo}>
      <video
        src={`api${videoUrl}`}
        type="video/mp4"
        autoPlay
        loop
        muted
      ></video>
      <NumberSection />
    </div>
  );
};

export default WelcomeVideo;
