import React from "react";

import styles from "./PhoneNumbersSelector.module.css";

const PhoneNumbersSelector = ({
  areaNumber,
  phoneNumber,
  setPhoneNumber,
  showCheckBox,
  provisionNumber,
  setProvisionNumber,
}) => {
  return (
    <div className={styles.phoneNumbers}>
      <div className={styles.radioContainer}>
        {areaNumber &&
          areaNumber.map((el, idx) => (
            <div className={styles.radio} key={idx}>
              <input
                type="radio"
                id={el}
                name="areaNumber"
                onChange={() => setPhoneNumber(el)}
                value={el}
                checked={phoneNumber === el}
              />
              <label htmlFor={el}>{el}</label>
            </div>
          ))}
      </div>
      {showCheckBox && (
        <div className={styles.checkBox}>
          <span> Actually Provision Phone Number - </span>
          <input
            type="checkbox"
            value={provisionNumber}
            onChange={() => setProvisionNumber((prev) => !prev)}
          />
        </div>
      )}
    </div>
  );
};

export default PhoneNumbersSelector;
