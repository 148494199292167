import React from "react";
import { Container, Row } from "react-bootstrap";
import { StyledCard, StyledCol, StyledCardTitle } from "./InfoCard.styled";
import "./InfoCard.css";

const InfoCard = ({ title, infoMap = {}, dark }) => {
  const entries = Object.entries(infoMap);

  return (
    <StyledCard dark={dark}>
      <StyledCardTitle>{title}</StyledCardTitle>
      <Container>
        {entries.map(([key, value]) => {
          if (typeof value !== "string") return null;

          const isLong = value.length > 30;
          const displayValue =
            value.length > 30 ? `${value.substring(0, 10)}...` : value;

          return (
            <Row key={key}>
              <StyledCol bold>{key + ":"}</StyledCol>
              <StyledCol xs="auto">
                <span
                  className={isLong ? "truncated-text" : ""}
                  title={isLong ? value : ""}
                >
                  {displayValue}
                </span>
              </StyledCol>
            </Row>
          );
        })}
      </Container>
    </StyledCard>
  );
};

export default InfoCard;
