import styled from "styled-components";
import { Paper } from "@mui/material";

export const ChatContainer = styled(Paper)`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"} !important;
  background-color: ${(props) =>
    props.isHumanTakeover ? "#451d2b" : ""} !important;
  color: #f5f5f5 !important;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 10px;

  @media (max-width: 768px) {
    margin: 15px;
    width: calc(100% - 25px);
    height: auto;
    min-height: 50vh;
    padding: 10px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${(props) =>
    props.author === "OutgoingToSuspect" ? "flex-end" : "flex-start"};
  width: 100%;
  margin: 10px 0;
`;
