import { Button } from "react-bootstrap";
import { PrimaryButton } from "../../shared/styles/Buttons.styled";

const LoadingButton = ({
  children,
  onClick,
  loading,
  disabled,
  primary,
  style,
}) => {
  const ButtonComponent = primary ? PrimaryButton : Button;

  return (
    <ButtonComponent
      variant="primary"
      type="submit"
      disabled={loading || disabled}
      onClick={onClick}
      style={style}
    >
      {loading ? "Processing..." : children}
    </ButtonComponent>
  );
};

export default LoadingButton;
