import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../redux/slices/themeSlice";
import { Icon } from "@iconify/react";
import styles from "./ThemeButton.module.css";

const ThemeButton = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  return (
    <button
      className={styles.themeButton}
      onClick={() => dispatch(toggleTheme())}
    >
      {theme === "light" ? (
        <Icon icon="ph:sun" color="white" />
      ) : (
        <Icon icon="ph:moon" color="white" />
      )}
    </button>
  );
};

export default ThemeButton;
