import React, { useState, useEffect } from "react";
import CountUp from "react-countup";

import classes from "./WelcomeVideo.module.css";

const NumberSection = () => {
  const [key, setKey] = useState(0);
  const [publicInfo, setPublicInfo] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, 14000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchPublicInfo = async () => {
      const response = await fetch("/api/platformdata", {
        method: "GET",
      });

      const data = await response.json();

      setPublicInfo(data);
    };
    fetchPublicInfo();
  }, []);

  return (
    <div className={classes.numberSection}>
      <div className={classes.item}>
        <CountUp
          key={key}
          end={publicInfo?.totalSuspectsFound}
          duration={10}
          separator=","
        />
        <p># BUYERS</p>
      </div>
      <div className={classes.item}>
        <CountUp
          key={key + 1}
          end={
            Number(publicInfo?.totalMessagesIncomingToBots || 0) +
            Number(publicInfo?.totalMessagesOutgoingToSuspects || 0)
          }
          duration={10}
          separator=","
        />
        <p># TEXTS</p>
      </div>
      <div className={classes.item}>
        <CountUp
          key={key + 2}
          end={publicInfo?.totalTransactionsIntercepted}
          duration={10}
          separator=","
        />
        <p># DISRUPTIONS</p>
      </div>
    </div>
  );
};

export default NumberSection;
