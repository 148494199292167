import React from "react";
import { Dropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";

import styles from "./BotForm.module.css";

const BotFormDropdown = ({ label, icon, value, handleClick, data }) => {
  return (
    <div className={styles.formItem}>
      <div className={styles.textIcon}>
        <Icon icon={icon} />
        <span className={styles.label}>{label}</span>
      </div>
      <div className={styles.formItemField}>
        <Dropdown className={styles.dropDown}>
          <Dropdown.Toggle className={styles.toggle}>
            {value?.name || `Choose Your ${label}`}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {data.map((element) => (
              <Dropdown.Item
                key={element.id}
                eventKey={element.name}
                active={value === element.name}
                onClick={() => handleClick(element)}
              >
                {element.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default BotFormDropdown;
