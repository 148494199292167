import { createSlice } from "@reduxjs/toolkit";

export const suspectSlice = createSlice({
  name: "suspect",
  initialState: {
    data: [],
    formattedData: [],
    selectedSuspect: false,
  },
  reducers: {
    setSuspectData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedSuspect: (state, action) => {
      state.selectedSuspect = action.payload;
    },
    setFormattedSuspectData: (state, action) => {
      state.formattedData = action.payload;
  },
}});

// Action creators are generated for each case reducer function
export const { setSuspectData, setFormattedSuspectData, setSelectedSuspect} = suspectSlice.actions;

export default suspectSlice.reducer;
