import styled from 'styled-components';

export const TableContainer = styled.div`
  background-color: #282C4D;
  border-radius: 5px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Thead = styled.thead`

`;

export const Tbody = styled.tbody`

`;

export const Tr = styled.tr`

`;

export const Th = styled.th`
  text-align: center;
`;

export const Td = styled.td`
  text-align: center;
`;