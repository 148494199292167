import { Card } from "reactstrap";
import styled from "styled-components";

export const InfoCard = styled(Card)`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#262944"};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 1em;
  margin: 1em;
`;

export const EmptyButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  color: #aaaaaa;

  &:hover,
  &:active {
    background-color: transparent;
    color: #aaaaaa;
  }

  &:focus {
    background-color: transparent;
    color: #aaaaaa;
    outline: none;
  }
`;
