import styled from "styled-components";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../shared/UniversalTable.styled";
import { Pagination } from "react-bootstrap";

export const OpsTableContainer = styled(TableContainer)`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#282C4D"};
`;

export const OpsTable = styled(Table)``;

export const OpsThead = styled(Thead)``;

export const OpsTbody = styled(Tbody)``;

export const OpsTr = styled(Tr)``;

export const OpsTh = styled(Th)``;

export const OpsTd = styled(Td)``;

export const PaginationButton = styled(Pagination)`
  user-select: none;
  --bs-pagination-color: #fff;
  --bs-pagination-bg: transparent;
  --bs-pagination-hover-color: black;
`;

export const TablePagination = styled.div`
  font-size: 16px;
`;
