import React, { useState, useEffect } from "react";
import "./BotDisplay.css";
import Bot from "./Bot";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from "../../utils/authPopup.js";
import Spinner from "react-bootstrap/Spinner";
import blur from "../../../src/images/blur_botDisplay.png";
import blur2 from "../../../src/images/botdisplayaccessdenied.png";

const BotDisplay = ({ instance, organizationId }) => {
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";

  const [operations, setOperations] = useState([]);
  const navigate = useNavigate();
  const [selectedOperation, setSelectedOperation] = useState();
  const [showAllOperations, setShowAllOperations] = useState(false);
  const [bots, setBots] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(0);
  const [noBots, setNoBots] = useState(false);

  async function getBots(msalContext) {
    setLoading(true);
    setError(false);
    try {
      if (showAllOperations) {
        const data = await fetchWithAuth(
          msalContext,
          "GET",
          `api/organization/${organizationId}/bots`
        );

        let operationData = await fetchWithAuth(
          msalContext,
          "GET",
          `api/organization/${organizationId}/operations`
        );

        const finishData = operationData.map((operation) => ({
          operation,
          botData: data.filter(
            (element) => operation.id === element.operationId
          ),
        }));

        setOperations(operationData);
        setSelectedOperation();
        if (data.length === 0) {
          setNoBots(true);
        }
        setBots(finishData);
      } else if (selectedOperation) {
        const data = await fetchWithAuth(
          msalContext,
          "GET",
          `api/organization/${organizationId}/bots`
        );

        const finishData = data.filter(
          (element) => selectedOperation.id === element.operationId
        );

        setBots([
          {
            operation: selectedOperation,
            botData: finishData,
          },
        ]);
      }
    } catch (error) {
      if (error === "Forbidden") {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBots(instance).catch((error) => console.error(error));
  }, [selectedOperation, showAllOperations, organizationId]);

  useEffect(() => {
    const getOperations = async () => {
      try {
        const data = await fetchWithAuth(
          instance,
          "GET",
          `api/organization/${organizationId}/operations`
        );

        setOperations(data);
        setShowAllOperations(true);
      } catch (err) {
        setError(true);
        console.error(err);
      }
    };
    getOperations();
  }, []);

  return (
    <div className="BotDisplayContainer">
      <div className="BotsHeader">
        <div className="BotsHeaderLeft">
          <h1 className="BotTitle">Bots</h1>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{ color: theme === "light" ? "#15135b" : "#19dccd" }}
            >
              {!showAllOperations
                ? selectedOperation
                  ? `${selectedOperation.name}`
                  : "Select Operation"
                : "Show All Operations"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[{ name: "Show all operations", id: -1 }, ...operations].map(
                (operationData) => {
                  return operationData.id === -1 ? ( //if show all operations set to true
                    <Dropdown.Item
                      onClick={() => {
                        setShowAllOperations(true);
                        setSelectedOperation();
                      }}
                    >
                      {operationData.name}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => {
                        setShowAllOperations(false);
                        setSelectedOperation(operationData);
                      }}
                    >
                      {operationData.name}
                    </Dropdown.Item>
                  );
                }
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <button className="AddBotButton" onClick={() => navigate("create-bot")}>
          <Icon icon="ic:baseline-plus" fontSize="20px" /> Add new
        </button>
      </div>
      <hr className="BreakStyle" />
      <div className="BotsList">
        {loading ? (
          <div className="LoadingIcon">
            <Spinner animation="border" />
          </div>
        ) : error ? (
          <img style={{ width: "98%", marginLeft: "10px" }} src={blur2} />
        ) : !noBots ? (
          bots.map((botOperation, index) => {
            const operation = botOperation.operation;

            return (
              <div key={index} className="BotLayout">
                <div className={`${themeClass} OperationTitle`}>
                  {operation.name}
                  <div className="OperationDates">
                    {operation.startTime} - {operation.endTime}
                  </div>
                </div>
                <div className="BotList">
                  {botOperation.botData.map((botData, index) => (
                    <div className="Bot">
                      <Bot botData={botData} />
                    </div>
                  ))}
                </div>
                <hr className="BreakStyle" />
              </div>
            );
          })
        ) : (
          <img style={{ width: "98%", marginLeft: "10px" }} src={blur} />
        )}
      </div>
    </div>
  );
};

export default BotDisplay;
