import { useState, useEffect, useRef } from "react";

const useListScroll = (fetch, hasMore) => {
  const listRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = listRef.current.scrollTop;
    const scrollHeight = listRef.current.scrollHeight;
    const clientHeight = listRef.current.clientHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    if (isBottom && hasMore) {
      fetch();
    }
  }, [isBottom]);

  return { listRef, handleScroll };
};

export default useListScroll;
