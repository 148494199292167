import { Icon } from '@iconify/react';
import styled from 'styled-components';

export const UniversalNavContainer = styled.div`
    display: flex;
    /* equal height of the children */
    justify-content: space-evenly;
`;

export const UniversalIcon = styled(Icon)`
    margin: 3px 5px 5px 5px;

`;