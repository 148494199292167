import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../utils/authPopup";
import { useSelector } from "react-redux";

const useFetchSuspects = (instance) => {
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const [suspects, setSuspects] = useState([]);
  const [hasMoreSuspects, setHasMoreSuspects] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [continuationToken, setContinuationToken] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const fetchSuspects = async () => {
    setLoading(true);
    try {
      let url = `api/organization/${selectedOrg.id}/suspects/suspects-paged`;

      if (continuationToken) {
        url += `?continuationToken=${encodeURIComponent(continuationToken)}`;
      }

      const data = await fetchWithAuth(instance, "GET", url);

      setSuspects((prevSuspects) => [...prevSuspects, ...data.suspects]);

      setContinuationToken(data.continuationToken);

      if (!data.continuationToken) setHasMoreSuspects(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) return;

    setSuspects([]);
    setHasMoreSuspects(true);
    setContinuationToken(null);

    // refetch should be handled with another useEffect, so above state changes are reflected.
    setRefetch((prev) => !prev);
  }, [selectedOrg]);

  useEffect(() => {
    if (loading) return;
    fetchSuspects();
  }, [refetch]);

  return { suspects, fetchSuspects, hasMoreSuspects, error };
};

export default useFetchSuspects;
