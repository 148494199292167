import { toast } from "react-toastify";

const handleError = (error) => {
  let errorMessage = "An error occurred";

  if (typeof error === "object" && error.message !== null) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  }

  toast.error(errorMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export default handleError;
