import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithAuth } from "../../../utils/authPopup";
import { setOrgData, setSelectedOrg } from "../../../redux/slices/organization";

const useChangeOrgData = (instance) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const { location, primaryContact } = selectedOrg;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: selectedOrg.name,
    description: selectedOrg.description || "",
    primaryContactEmail: primaryContact.email || "",
    primaryContactName: primaryContact.name || "",
    primaryContactPhoneNumber: primaryContact.phoneNumber || "",
    address: location?.address1 || "",
    primaryContactTitle: primaryContact.title || "",
  });

  useEffect(() => {
    setFormData({
      name: selectedOrg.name,
      description: selectedOrg.description || "",
      primaryContactEmail: primaryContact.email || "",
      primaryContactName: primaryContact.name || "",
      primaryContactPhoneNumber: primaryContact.phoneNumber || "",
      address: location?.address1 || "",
      primaryContactTitle: primaryContact.title || "",
    });
  }, [selectedOrg]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const {
    name,
    primaryContactPhoneNumber,
    description,
    primaryContactName,
    primaryContactEmail,
    address,
    primaryContactTitle,
  } = formData;

  const changeData = (selectedOrg) => {
    const updatedOrg = { ...selectedOrg };

    updatedOrg.name = name;
    updatedOrg.description = description;

    updatedOrg.location = {
      ...selectedOrg.location,
      address1: address,
    };

    updatedOrg.primaryContact = {
      email: primaryContactEmail,
      name: primaryContactName,
      phoneNumber: primaryContactPhoneNumber,
      title: primaryContactTitle,
    };

    return updatedOrg;
  };

  const submitChangedOrgData = async () => {
    setLoading(true);
    try {
      const newOrgObj = changeData(selectedOrg);

      await fetchWithAuth(
        instance,
        "PUT",
        `api/organization/${selectedOrg.id}`,
        newOrgObj
      );

      const data = await fetchWithAuth(instance, "GET", "api/organization");

      dispatch(setOrgData(data));
      dispatch(
        setSelectedOrg(data.find((org) => org.id === selectedOrg.id) || data[0])
      );
    } catch (error) {
      console.error("Error updating organization:", error);
    } finally {
      setLoading(false);
    }
  };

  return { formData, changeHandler, submitChangedOrgData, loading };
};

export default useChangeOrgData;
