import { Icon } from '@iconify/react';

/**
 * Component to render in sorting fields like a table
 * @param {string} sortField - Name of the sort field to indicate when this icon should appear
 * @param {string} currField - Name of the current sort field in the parent component
 * @param {string} sortOrder - Current order of the sort (asc or desc)
 * @returns {JSX.Element} - The SortIcon component
 */
export default function SortIcon({ sortField, currField, sortOrder }) {
    return (
        sortField === currField && (
            <Icon
                className={sortOrder === 'asc' ? 'ms-3 mt-2' : 'ms-3 mb-2'}
                icon={sortOrder === 'asc' ? 'fa-solid:sort-up' : 'fa-solid:sort-down'}
                color="#aaa"
            />
        ));
}