import styled from "styled-components";
import { Button } from 'reactstrap';

export const PrimaryButton = styled(Button)`
    background-color: #384098;
    border-color: #384098;
    --bs-btn-active-bg: #aeb4ff;

    &:hover {
        background-color: #535896;
        border-color: #535896;
    }
`;

export const SecondaryButton = styled(Button)`
    background-color: #262944;
    border-color: #262944;
`;