import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Icon } from "@iconify/react";
import styles from "./Welcome.module.css";
import totalSuspects from "../../images/total_suspects.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const PublicInfo = () => {
  const [publicInfo, setPublicInfo] = React.useState(null);

  useEffect(() => {
    const fetchPublicInfo = async () => {
      const response = await fetch("/api/platformdata", {
        method: "GET",
      });

      const data = await response.json();
      setPublicInfo(data);
    };
    fetchPublicInfo();
  }, []);

  const barData = {
    labels: [""], // Single empty label to align both bars, but invisible
    datasets: [
      {
        label: "Incoming to Bots",
        data: publicInfo ? [publicInfo.totalMessagesIncomingToBots] : [0],
        backgroundColor: "#C2185B",
        borderColor: "#C2185B",
        borderWidth: 1,
      },
      {
        label: "Outgoing to Suspects",
        data: publicInfo ? [publicInfo.totalMessagesOutgoingToSuspects] : [0],
        backgroundColor: "#1A237E",
        borderColor: "#1A237E",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "right",
        formatter: (value) => value.toLocaleString(),
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };

  return (
    <div className={styles.publicInfo}>
      {publicInfo ? (
        <div>
          <div className={styles.info}>
            <div className={styles.infoHeader}>
              <Icon icon="radix-icons:dot-filled" />
              <h2>Total Operations</h2>
            </div>
            <div className={styles.metric}>
              <span>{publicInfo?.totalOperations}</span>
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.infoHeader}>
              <Icon icon="radix-icons:dot-filled" />
              <h2>Total Bots</h2>
            </div>
            <div className={styles.metric}>
              <span>{publicInfo?.totalBots}</span>
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.infoHeader}>
              <Icon icon="radix-icons:dot-filled" />
              <h2>Total Suspects Found</h2>
            </div>
            <div className={styles.totalSuspects}>
              <span>{publicInfo?.totalSuspectsFound}</span>
              <img src={totalSuspects} alt="total suspects" />
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.infoHeader}>
              <Icon icon="radix-icons:dot-filled" />
              <h2>Total Messages</h2>
            </div>
            <div>
              <Bar data={barData} options={barOptions} />
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default PublicInfo;
