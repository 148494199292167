import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Operations from "../../components/charts/Operations";
import Suspects from "../../components/charts/Suspects";
import Messages from "../../components/charts/Messages";
import Bots from "../../components/charts/Bots";
import { useSelector } from "react-redux";
import OperationsMiniCard from "../../shared/MiniTable/OperationsMiniCard";
import BotsMiniCard from "../../shared/MiniTable/BotsMiniCard";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from "../../utils/authPopup";
import Spinner from "react-bootstrap/Spinner";
import mapBlur from "../../images/mapcomingsoon.png";
import blur from "../../images/blur.avif";
import blurEntireScreen from "../../../src/images/homeacessdenied.png";
import "../../custom.css";
import brovado from "../../components/charts/No data charts/brovado2.png";

function Home({ instance }) {
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const navigate = useNavigate();
  const [operations, setOperations] = useState(null);
  const [chartData, setChartData] = useState(false);
  const [botData, setBotData] = useState([]);
  const [error, setError] = useState(false);

  function editOrg() {
    navigate("/edit-organization");
  }

  async function grabOperations() {
    if (selectedOrg === false) return;
    setError(false);

    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/operations/active`
      );

      setOperations(data);
    } catch (error) {
      setOperations(null);
      if (error === "Forbidden") setError(true);
    }
  }

  async function displayChartData() {
    if (selectedOrg === false) return;
    setError(false);

    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/summary`
      );

      setChartData(data);
    } catch (error) {
      setChartData(false);
      if (error === "Forbidden") setError(true);
    }
  }

  async function grabBots() {
    if (selectedOrg === false) return;
    setError(false);

    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/bots/active`
      );
      setBotData(data);
    } catch (error) {
      setBotData([]);
      if (error === "Forbidden") setError(true);
    }
  }

  useEffect(() => {
    displayChartData();
    grabOperations();
    grabBots();
  }, [selectedOrg]);

  function operationDisplay() {
    if (operations === null || operations.length === 0) {
      return (
        <tr>
          <td className="w-75">No Active Operations</td>
        </tr>
      );
    } else {
      return operations.map((item, index) => (
        <tr key={index}>
          <td className="w-75">{item.name}</td>
          <td>
            <Icon icon="material-symbols:info-outline" color="#f8f8f8" />
          </td>
        </tr>
      ));
    }
  }

  function displayBots() {
    if (botData.length === 0) {
      return (
        <tr>
          <td>No Active Bots</td>
        </tr>
      );
    } else {
      return botData.map((item, index) => (
        <tr key={index}>
          <td>{item.properties.Name}</td>
          <td>{item.formattedBotPhoneNumber}</td>
        </tr>
      ));
    }
  }

  return (
    <div className="home">
      {error ? (
        <>
          <div className="org-name-container">
            <h1 className="org-name">
              {`${selectedOrg.name}`}{" "}
              <Icon
                cursor="pointer"
                onClick={editOrg}
                icon="fluent:edit-12-regular"
              ></Icon>
            </h1>

            <br></br>
          </div>
          <img src={blurEntireScreen} width={"100%"} />
        </>
      ) : (
        <AuthenticatedTemplate>
          {selectedOrg === false ? (
            <div className="LoadingIcon">
              <Spinner animation="border" />{" "}
            </div>
          ) : (
            <div className="org-name-container">
              <h1 className="org-name">
                {`${selectedOrg.name}`}{" "}
                <Icon
                  cursor="pointer"
                  onClick={editOrg}
                  icon="fluent:edit-12-regular"
                ></Icon>{" "}
              </h1>

              <br />
            </div>
          )}
          {chartData === false || selectedOrg === false ? (
            <div className="LoadingIcon">
              <img width="100%" src={brovado} />{" "}
            </div>
          ) : (
            <div className="charts">
              <Operations
                blur={blur}
                operationsData={chartData?.operationsChartDetails}
              />
              <Messages
                blur={blur}
                messagesData={chartData?.messageChartDetails}
              />
              <Suspects
                blur={blur}
                suspectsData={chartData?.suspectChartDetails}
              />
              <Bots blur={blur} botsData={chartData?.botsChartDetails} />
            </div>
          )}
          {selectedOrg === false ? null : (
            <div className="customLuka">
              <div className="next panel-container">
                <OperationsMiniCard
                  title="Active Operations"
                  content={operationDisplay()}
                />
              </div>
              <div className="next">
                <img
                  style={{ borderRadius: "10px" }}
                  width={"100%"}
                  src={mapBlur}
                />
                {/* <Map
                  position={[36.33982922408236, -87.53951409346624]}
                  markers={markers}
                /> */}
              </div>
              <div className="next bots panel-container">
                <BotsMiniCard
                  title="Active Bots"
                  items={botData.length > 0 ? botData : null}
                  content={displayBots()}
                />
              </div>
            </div>
          )}
        </AuthenticatedTemplate>
      )}
    </div>
  );
}

export default Home;

const markers = [
  [30.248935423456288, -97.943213535544],
  [30.598331828084913, -81.81279229748522],
  [37.297507702341164, -77.62582888999866],
  [35.93472493783899, -86.7908409570775],
  [33.540836614670866, -84.30181662728137],
  [34.99182573071041, -92.48279581978966],
  [39.86123098039068, -85.94187141823231],
];
