import styled from "styled-components";
import { Card } from "reactstrap";

export const MiniCardContainer = styled(Card)`
  background-color: ${(props) =>
    props.theme === "light" ? "#f4f3f8" : "#2a2f62"};
  border-radius: 10px;
  padding: 1em;
  border: none;

  p {
    margin: 5px 8px 12px 0;
  }
`;

export const MiniTable = styled.table`
  width: 100%;
  table-layout: fixed;

  thead {
    font-size: 20px;
  }

  th,
  td {
    padding: 0.5em;
    border-bottom: 1px solid
      ${(props) => (props.theme === "light" ? "#f8f8f84d" : "#0a0e37")};
  }
`;
