// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
import { useMsal } from "@azure/msal-react";
import { msalConfig, b2cPolicies, tokenRequest } from "./authConfig.js";
import store from "../redux/store.js";
import { setActiveAccount, setIsLoggedIn } from "../redux/slices/auth.js";

let accountId = "";
let accessToken = null;
export let activeAccount;
export let myMSALObj = null;

function setAccount(account) {
  accountId = account.homeAccountId;
  activeAccount = account;
}

function selectAccount() {
  const currentAccounts = myMSALObj.getAllAccounts();
  const msaldata = store.getState().settings.msaldata.payload;
  const msal = msalConfig(msaldata);
  const b2c = b2cPolicies(msaldata);

  if (currentAccounts.length < 1) {
    return;
  } else if (currentAccounts.length > 1) {
    /**
     * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
     * is cached as a new account, which results in more than one account in the cache. Here we make
     * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
     * as this is the default flow the user initially signed-in with.
     */
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId
          .toUpperCase()
          .includes(b2c.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(b2c.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === msal.auth.clientId
    );

    if (accounts.length > 1) {
      // localAccountId identifies the entity for which the token asserts information.
      if (
        accounts.every(
          (account) => account.localAccountId === accounts[0].localAccountId
        )
      ) {
        // All accounts belong to the same user
        setAccount(accounts[0]);
      } else {
        // Multiple users detected. Logout all to be safe.
        signOut();
      }
    } else if (accounts.length === 1) {
      setAccount(accounts[0]);
    }
  } else if (currentAccounts.length === 1) {
    setAccount(currentAccounts[0]);
  }
}

function handleAadResponse(response) {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response !== null) {
    setAccount(response.account);
  } else {
    selectAccount();
  }
  return response;
}

export function signIn(e, instance) {
  myMSALObj = instance;
  const msaldata = store.getState().settings.msaldata.payload;
  const msal = msalConfig(msaldata);

  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  myMSALObj
    .loginRedirect(msal);
}

export function signOut(e, instance) {
  if (instance != null) {
    myMSALObj = instance;
  }

  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  const msaldata = store.getState().settings.msaldata.payload;
  const msal = msalConfig(msaldata);

  const logoutRequest = {
    postLogoutRedirectUri: msal.auth.redirectUri,
    mainWindowRedirectUri: msal.auth.redirectUri,
  };

  myMSALObj.logoutRedirect(logoutRequest).then(() => {
    store.dispatch(setIsLoggedIn(false));
    store.dispatch(setActiveAccount({}));
  });
}

function getTokenPopup() {
  const msaldata = store.getState().settings.msaldata.payload;

  var request = tokenRequest(msaldata);
  selectAccount();
  request.account = activeAccount;

  return myMSALObj
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        throw new useMsal.InteractionRequiredAuthError();
      }
      return response;
    })
    .catch((error) => {
      console.error(
        "Silent token acquisition fails. Acquiring token using popup. \n",
        error
      );
      if (error instanceof useMsal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj
          .acquireTokenRedirect(request)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error(error);
      }
    });
}

function passTokenToApi() {
  getTokenPopup().then((response) => {
    if (response) {
      console.log("access_token acquired at: " + new Date().toString());
      try {
        //callApi(authConfig.apiConfig.webApi, response.accessToken);
      } catch (error) {
        console.error(error);
      }
    }
  });
}

/**
 * To initiate a B2C user-flow, simply make a login request using
 * the full authority string of that user-flow e.g.
 * https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2
 */
function editProfile() {
  const msaldata = store.getState().settings.msaldata.payload;
  const b2c = b2cPolicies(msaldata);

  const editProfileRequest = b2c.authorities.editProfile;
  editProfileRequest.loginHint =
    myMSALObj.getAccountByHomeId(accountId).username;

  myMSALObj.loginPopup(editProfileRequest).catch((error) => {
    console.error(error);
  });
}

export function fetchWithAuth(instance, method, url, body) {
  myMSALObj = instance;
  return getTokenPopup().then((response) => {
    if (response) {
      console.log("access_token acquired at: " + new Date().toString());

      try {
        var requestOptions = {
          method,
          headers: authHeader(response.accessToken, url),
        };

        if (body) {
          if (body instanceof FormData) {
            requestOptions.body = body;
          } else {
            requestOptions.headers["Content-Type"] = "application/json";
            requestOptions.body = JSON.stringify(body);
          }
        }

        return fetch(url, requestOptions).then(handleFetchResponse);
      } catch (error) {
        console.error(error);
      }
    }
  });
}

function authHeader(accessToken, url) {
  // return auth header with jwt if user is logged in and request is to the api url

  const isLoggedIn = !!accessToken;
  const isApiUrl = url.startsWith("api");
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${accessToken}` };
  } else {
    return {};
  }
}

function handleFetchResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].includes(response.status) && accessToken) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        signOut();
      }
      const error =
        (data && (data.message || data.errors.File[0])) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
