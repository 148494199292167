import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import './UniversalNav.css';
import { UniversalIcon, UniversalNavContainer } from "./UniversalNav.styled";

function NavButton({ location, icon, children }) {
  const navigate = useNavigate();
  const currentPath = useLocation();

  const isActive = location === "/" && currentPath.pathname === "/" || location !== "/" && currentPath.pathname.startsWith(location);

  return (
    <>
      <div
        className={`UniversalNavButton ms-hiddenMdDown ${isActive ? 'active' : ''}`}
        onClick={() => navigate(location)}
        active={isActive}>
        <UniversalIcon icon={icon} color="white" />
        {children}
      </div>
      <OverlayTrigger
        placement={"top"}
        overlay={
          <Tooltip id={`tooltip-${children}`}>
            {children}
          </Tooltip>
        }
      >
        <div
          className={`UniversalNavButton ms-hiddenLgUp ${isActive ? 'active' : ''}`}
          onClick={() => navigate(location)}
          active={isActive}>
          <UniversalIcon icon={icon} color="white" />
        </div>
      </OverlayTrigger>
    </>
  )
}

function UniversalNav() {

  return (
    <UniversalNavContainer>
      <NavButton location={"/"} icon="material-symbols:dashboard-customize-outline-rounded">Dashboard</NavButton>
      <NavButton location={"/operations"} icon="bx:category">Operations</NavButton>
      <NavButton location={"/bots"} icon="mdi:head-cog-outline">Bots</NavButton>
      <NavButton location={"/chats"} icon="material-symbols:chat-bubble-outline">Chats</NavButton>
      <NavButton location={"/suspects"} icon="ic:outline-people">Suspects</NavButton>
    </UniversalNavContainer>
  );
}


export default UniversalNav;
