import styled from 'styled-components';
import { Card, Col } from 'react-bootstrap';
import {CardTitle} from 'reactstrap';

export const StyledCard = styled(Card)`
    background-color: ${props => props.dark ? "#262944" : "#282C4D"}; 
    margin-top: 10px;
    border-radius: 10px;
    color: #FFFFFF;
    padding: 10px;
`;

export const StyledCol = styled(Col)`
    font-weight: ${props => props.bold ? "bold" : "none"};
    padding: 5px;
    display: flex;
    flex-direction: column;

    /* Make the last child grow to fill the column */
    & > :last-child {
        flex-grow: 1;
    }
`;

export const StyledCardTitle = styled(CardTitle)`
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #FFFFFF;
`;