import { Icon } from "@iconify/react";
import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../shared/styles/Buttons.styled";
import { fetchWithAuth } from "../../utils/authPopup.js";
import LoadingButton from "../shared/LoadingButton.js";

const AddOperation = ({ instance, organizationId, updateOpList }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deterrenceMessage, setDeterrenceMessage] = useState(
    "You are now permanently registered in the system as someone seeking to purchase sex online from an exploited individual.   The people in these ads are under force, fraud, and coercion, and you are directly contributing to their abuse by being part of the demand for HUMAN TRAFFICKING.   The details of this incident will be reviewed further, and law enforcement may be contacted, questioned or charged by local, state, or federal law enforcement.   For your sake and for the sake of others, please consider getting help now before it is too late. Remember, any and all further attempts to purchase sex will be reported immediately."
  );

  const toggle = () => {
    setName("");
    setLocation("");
    setStartDate("");
    setEndDate("");
    setModal(!modal);
  };

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!name || !location || !startDate || !endDate) {
      alert("Please fill in all fields.");
      return;
    }

    const now = new Date().toISOString();
    if (new Date(endDate).toISOString() <= now) {
      alert("End Date must be in the future.");
      return;
    }

    try {
      await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${organizationId}/operations/`,
        {
          deterrenceMessage,
          name,
          location,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
          isActive: true,
          fileUrl: "",
        }
      );
      toggle();
      updateOpList();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PrimaryButton
        onClick={toggle}
        style={{ minWidth: "100px" }}
        className="px-3 py-2 font-size-sm font-size-md-base"
      >
        <Icon icon="ic:baseline-plus" color="white" /> Add new
      </PrimaryButton>
      <Modal style={{ color: "black" }} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create new operation</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">Location</Label>
              <Input
                type="text"
                name="location"
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                required
                type="datetime-local"
                name="startDate"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Input
                required
                type="datetime-local"
                name="endDate"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <LoadingButton onClick={handleSubmit} loading={loading} primary>
            Submit
          </LoadingButton>
          <SecondaryButton color="secondary" onClick={toggle}>
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddOperation;
