import React from "react";
import { Icon } from "@iconify/react";

import styles from "./BotForm.module.css";

const BotFormText = ({ label, icon, value, handleChange }) => {
  return (
    <div className={styles.formItem}>
      <div className={styles.formIcon}>
        <Icon icon={icon} />
        <span style={{ marginLeft: "6px" }}>{label}</span>
      </div>
      <div className={styles.formItemField}>
        <input
          type="text"
          placeholder="At Least 3 characters"
          minLength="3"
          required
          value={value}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );
};

export default BotFormText;
