import React, { useState} from "react";
import {StyledCard, StyledCardTitle} from "../shared/InfoCard/InfoCard.styled";
import { SuspectNotesTextArea, SuspectNotesForm } from "./SuspectNotes.styled";
import { Col, Row, Button } from "react-bootstrap";
import{ useSelector, useDispatch } from "react-redux"
import { setSelectedSuspect } from "../../redux/slices/suspect";
import { fetchWithAuth } from "../../utils/authPopup";

function SuspectNotes({instance, suspect}) {
    const dispatch = useDispatch();
    const selectedOrg = useSelector((state) => state.organization.selectedOrg);
    const selectedSuspect = useSelector((state) => state.suspect.selectedSuspect);
    const suspectData = useSelector((state) => state.suspect.data);
    const [originalNotes, setOrginalNotes] = useState(selectedSuspect.notes);
    const [notes, setNotes] = useState(originalNotes);
    const [saved, setSaved] = useState(true);

    function handleChange(event) {
        setNotes(event.target.value);
        setSaved(event.target.value === originalNotes);
    }

    async function handleSave() {
        setSaved(true);
        setOrginalNotes(notes);
        let suspectCopy = [...suspectData.filter((suspects) => suspects.id == suspect.id)];
        let formattedCopy = {...suspect};
        formattedCopy.notes = notes;
        suspectCopy= {...suspectCopy[0]};
        suspectCopy.notes = notes;
        const data = await fetchWithAuth(
            instance,
            "PUT",
            `api/organization/${selectedOrg.id}/suspects/${suspect.id}`,
            suspectCopy);
        dispatch(setSelectedSuspect(formattedCopy));
    }

    function handleDiscard() {
        setNotes(originalNotes);
        setSaved(true);
    }

    return (
        <StyledCard dark>
            <StyledCardTitle>Suspect Notes</StyledCardTitle>
            <SuspectNotesForm>
                    <SuspectNotesTextArea as="textarea" placeholder={originalNotes}  value={notes} onChange={(e) => handleChange(e)}/>
                    <br />
                    <Row>
                        <Col />
                        <Col xs="auto">
                            <Button variant="secondary" disabled={saved} onClick={() => handleDiscard()}>Discard</Button>
                        </Col>
                        <Col xs="auto">
                            <Button variant="primary" disabled={saved} onClick={() => handleSave()}>Save</Button>
                        </Col>
                    </Row>
            </SuspectNotesForm>
        </StyledCard>
    )
}

export default SuspectNotes;

