import { fetchWithAuth } from "../../../utils/authPopup";

const convoSeen = (
  instance,
  setConversations,
  organizationId,
  selectedConvo
) => {
  setConversations((prevConversations) =>
    prevConversations.map((conversation) =>
      conversation.id === selectedConvo.id
        ? { ...conversation, unreadMessages: 0 }
        : conversation
    )
  );

  fetchWithAuth(
    instance,
    "PUT",
    `api/organization/${organizationId}/conversations/${selectedConvo.id}/seen`
  );
};

export default convoSeen;
